import React, { useRef, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import useWxShare from "../hooks/wx-share";
import useUserInfo from "../hooks/user-info";
import useReadImage from '../hooks/read-image';
import useReadImages from '../hooks/read-images';
import './main.css';

function Main(props) {
  useWxShare({ title: props.shareTitle, link: props.shareLink });
  const userInfo = useUserInfo(props.token);
  const template = useReadImage(props.template);
  const portrait = useReadImage(userInfo && userInfo.portrait);
  const images = useReadImages(props.images);
  const canvasRef = useRef(null);
  const context = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const onUserInfoLoaded = props.onUserInfoLoaded;
    onUserInfoLoaded && userInfo && onUserInfoLoaded(userInfo);
  }, [userInfo, props.onUserInfoLoaded]);
  
  useEffect(() => {
    if (
      !template || !userInfo || !portrait || !canvasRef.current
      || (props.images && !images) // 额外的图片尚未加载完成
    ) {
      return;
    }
    const width = props.width;
    const height = props.height;
    const drawPortrait = props.drawPortrait;
    const drawInfo = props.drawInfo;
    const drawImages = props.drawImages;
    const beforeEnding = props.beforeEnding;

    canvasRef.current.style.width = `${width}px`;
    canvasRef.current.style.height = `${height}px`;
    canvasRef.current.width = width;
    canvasRef.current.height = height;
    context.current = canvasRef.current.getContext('2d');
    if (props.backgroundColor) {
      context.current.fillStyle = props.backgroundColor;
      context.current.fillRect(0, 0, width, height);
    }
    context.current.drawImage(template, 0, 0);

    drawPortrait && drawPortrait(context.current, portrait, width, height);
    drawInfo && drawInfo(context.current, userInfo, width, height);
    images && drawImages && drawImages(context.current, images);

    if (beforeEnding) {
      beforeEnding(context.current).then(() => {
        setImage(canvasRef.current.toDataURL());
      })
    } else {
      setImage(canvasRef.current.toDataURL());
    }
  },[
    props.width, props.height, props.drawPortrait, props.drawInfo, props.drawImages, props.beforeEnding, props.backgroundColor, props.images,
    template, userInfo, portrait, images,
  ]);
  
  if (!image) {
    return (
      <div className="page-result">
        <div className="main-canvas">
          <canvas ref={canvasRef} />
        </div>
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="page-result" style={props.pageStyle}>
      <div className="main-image">
        <img src={image} alt="" />
      </div>
      {props.children}
    </div>
  )
}

export default Main;
