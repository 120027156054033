import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { autoWrapString, fillRoundRect } from '../../../utils';

// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// const monospaceFont = isMobile ? 'Monospace' : 'Consolas';
const monospaceFont = 'Consolas'; // 总结直接用这个

const TITLE_X = 41; //
const TITLE_HEIGHT = 27; // 大标题的高度
const TITLE_WIDTH = 104;
const TEXT_FONT_SIZE = 16;
const TEXT_LINE_HEIGHT = 28;
const TEXT_BLOCK_WIDTH = 329;

const getCommentParagraph1 = (name) =>
    `恭喜${name}完成信奥编程体验课的全部学习！阶段测验安排在周末，记得好好复习哦！`;
const COMMENT_PARAGRAPH2 =
    '现在你对编程是否有了更多的了解？是不是能够自己动手编写一些有趣的代码了？';
const COMMENT_PARAGRAPH3 =
    '希望同学们课后要多多动手尝试，编程就是实践出真知~有疑惑也要及时问老师，弄明白才能帮助后续的学习呀~加油！';

export default function useTeacherCommentBlock(width, resizeRatio, name) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/26/FS8uRQIr6f2u803zlq3Lt.png!png'
    );
    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && title && name) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度
            // 白色块高度
            ctx.font = `bold ${Math.floor(
                TEXT_FONT_SIZE * resizeRatio
            )}px ${monospaceFont}`;
            const [, textHeight1] = handleText(
                ctx,
                getCommentParagraph1(name),
                TEXT_BLOCK_WIDTH * resizeRatio
            );
            const [, textHeight2] = handleText(
                ctx,
                COMMENT_PARAGRAPH2,
                TEXT_BLOCK_WIDTH * resizeRatio
            );
            const [, textHeight3] = handleText(
                ctx,
                COMMENT_PARAGRAPH3,
                TEXT_BLOCK_WIDTH * resizeRatio
            );
            const blockHeight =
                2 +
                18 +
                textHeight1 +
                16 +
                textHeight2 +
                16 +
                textHeight3 -
                TEXT_LINE_HEIGHT +
                TEXT_FONT_SIZE +
                18 +
                2;
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + TITLE_HEIGHT;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                TITLE_X * resizeRatio,
                currentHeight,
                TITLE_WIDTH * resizeRatio,
                TITLE_HEIGHT * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += TITLE_HEIGHT;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#F08200');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 教师评价
            currentHeight += 18;
            currentHeight +=
                16 +
                drawText(
                    ctx,
                    { x0: (414 - TEXT_BLOCK_WIDTH) / 2, y0: currentHeight },
                    TEXT_BLOCK_WIDTH,
                    resizeRatio,
                    getCommentParagraph1(name)
                );
            currentHeight +=
                16 +
                drawText(
                    ctx,
                    { x0: (414 - TEXT_BLOCK_WIDTH) / 2, y0: currentHeight },
                    TEXT_BLOCK_WIDTH,
                    resizeRatio,
                    COMMENT_PARAGRAPH2
                );
            currentHeight +=
                18 +
                drawText(
                    ctx,
                    { x0: (414 - TEXT_BLOCK_WIDTH) / 2, y0: currentHeight },
                    TEXT_BLOCK_WIDTH,
                    resizeRatio,
                    COMMENT_PARAGRAPH3
                );

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [resizeRatio, width, title, name, image]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}

const handleText = (ctx, text, textWidth) => {
    const displayTextList = autoWrapString(ctx, text, textWidth);
    return [displayTextList, displayTextList.length * TEXT_LINE_HEIGHT];
};

const drawText = (ctx, { x0, y0, x1, y1 }, width, resizeRatio, text) => {
    const codeX = x0 * resizeRatio;
    const codeY = y0 * resizeRatio;
    const codeWidth = width * resizeRatio;

    ctx.font = `bold ${Math.floor(
        TEXT_FONT_SIZE * resizeRatio
    )}px ${monospaceFont}`;
    const [finalTextList, textHeight] = handleText(ctx, text, codeWidth);
    // 绘制分析
    ctx.fillStyle = '#3A59C6';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    finalTextList.forEach((line, i) => {
        ctx.fillText(
            `${line}`,
            codeX,
            codeY + i * TEXT_LINE_HEIGHT * resizeRatio
        );
    });
    return textHeight;
};
