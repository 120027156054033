import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Main from '../components/main';
import './qianren-cert.less';

export default withRouter(function Playground(props) {
  const { token } = props.match.params;
  const [error, setError] = useState(false);
  const drawInfo = (ctx, info) => {
    if (!info.public.qianrenLessons) {
      setError(true);
    }
    ctx.fillStyle = '#000';
    ctx.font = '14px Arial';
    ctx.fillText(`恭喜学员`, 298, 450);
    ctx.font = 'bold 14px Arial';
    ctx.fillText(`${info.public.realName || info.name}`, 360, 450);
    const width = ctx.measureText(`${info.public.realName || info.name}`).width;
    ctx.beginPath();
    ctx.strokeStyle = '#000';
    ctx.lineWidth = 1;
    ctx.moveTo(360, 458);
    ctx.lineTo(360 + width, 458);
    ctx.stroke();
  }
  if (error) {
    return <div>404 - Not Found</div>
  }
  return <div className="page-qianren-cert" style={{
    minHeight: '100vh',
    backgroundColor: '#E17A55',
  }}><Main
    token={token}
    template="https://staticcdn.boyuai.com/materials/2020/02/25/PBzaTVAf94ocuOyTrSv1I.png!png"
    width={716}
    height={993}
    drawInfo={drawInfo}
  >
    <img className="bg" src="https://staticcdn.boyuai.com/materials/2020/02/23/qBwPmpSqjmP4fTbjWFt6N.png!png" alt="bg" />
    <img className="share-hint-1" src="https://staticcdn.boyuai.com/materials/2020/02/23/ian4gNButNd7Lucjt2XI9.png!png" alt="长按图片保存" />
  </Main></div>;
});
