import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../hooks/read-image';
import { fillRoundRect } from '../../utils';

// 这个自动换行比较规整，但是和旧函数的 text 不太一样
const handleText = (ctx, text, maxWidth, textLineHeight) => {
    var words = text.split(" ");
    var lines = [];
    var currentLine = words[0];

    for (var i = 1; i < words.length; i++) {
        var word = words[i];
        var width = ctx.measureText(currentLine + " " + word).width;
        if (width < maxWidth) {
            currentLine += " " + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);
    return [lines, lines.length * textLineHeight];
};

// 绘制上半部分描述
export const useDescriptionBlock = ({ resizeRatio, userInfo, theoreticalLevel, practiceLevel }) => {
    const [image, setImage] = useState(null);
    const canvasRef = useRef(null);
    const width = resizeRatio * 343;

    useEffect(() => {
        if (!canvasRef.current || !userInfo || !practiceLevel || !theoreticalLevel) {
            return;
        }

        const ctx = canvasRef.current.getContext('2d');
        const finishedMarginTop = 24 * resizeRatio;
        const titleMarginTop = 16 * resizeRatio;
        const starMarginTop = 24 * resizeRatio;
        const codeMarginTop = 8 * resizeRatio;
        const lineHeight = 24 * resizeRatio;
        const offset = 5;
        const height = (
            lineHeight // 第一行高度
            + finishedMarginTop + lineHeight
            + titleMarginTop + lineHeight
            + starMarginTop + lineHeight
            + codeMarginTop + lineHeight + offset
        )

        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        // 恭喜学员
        ctx.textAlign = 'center';
        ctx.fillStyle = '#3A59C6';
        ctx.font = `bold ${18 * resizeRatio}px Arial`;
        const name = userInfo?.name || ''
        const notChinese = /^[A-Za-z][A-Za-z0-9]*$/.test(name)
        const underline = notChinese ? '_' : '__'
        ctx.fillText(
            '恭喜学员 __' + underline.repeat(name.length) + '__',
            width / 2,
            lineHeight,
        );
        ctx.font = `bold ${24 * resizeRatio}px Arial`;
        ctx.fillText(
            name,
            width / 2 + 20 * resizeRatio * 2,
            lineHeight - offset,
        );
        let currentHeight = lineHeight;

        // 完成全部学习
        ctx.font = `bold ${15 * resizeRatio}px Arial`;
        ctx.fillText(
            '完成信奥编程体验课的全部学习，获得',
            width / 2,
            currentHeight + finishedMarginTop + lineHeight,
        );
        currentHeight += finishedMarginTop + lineHeight;

        // 抓虫小能手
        ctx.font = `bold ${15 * resizeRatio}px Arial`;
        ctx.fillText(
            '称号！',
            width / 2 + 130,
            currentHeight + titleMarginTop + lineHeight,
        );
        ctx.font = `bold ${24 * resizeRatio}px Arial`;
        ctx.fillText(
            '抓虫小能手',
            width / 2 - 40,
            currentHeight + titleMarginTop + lineHeight,
        );
        currentHeight += titleMarginTop + lineHeight;

        // 等级
        ctx.textAlign = 'right';
        ctx.font = `bold ${15 * resizeRatio}px Arial`;
        ctx.fillText(
            '理论知识理解：',
            width / 2,
            currentHeight + starMarginTop + lineHeight,
        );
        ctx.textAlign = 'left';
        ctx.fillText(
            '★'.repeat(theoreticalLevel),
            width / 2,
            currentHeight + starMarginTop + lineHeight,
        );
        ctx.textAlign = 'right';
        currentHeight += starMarginTop + lineHeight;
        ctx.font = `bold ${15 * resizeRatio}px Arial`;
        ctx.fillText(
            '代码实践能力：',
            width / 2,
            currentHeight + codeMarginTop + lineHeight,
        );
        ctx.textAlign = 'left';
        ctx.fillText(
            '★'.repeat(practiceLevel),
            width / 2,
            currentHeight + codeMarginTop + lineHeight,
        );

        setImage(canvasRef.current.toDataURL());
    }, [image, practiceLevel, resizeRatio, theoreticalLevel, userInfo, width])

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}

// 绘制动态高度的知识点区域
export const useKnowledgeBlock = (resizeRatio, title, tags) => {
    const [image, setImage] = useState(null);
    const canvasRef = useRef(null);
    const width = resizeRatio * 204;
    const lineHeight = resizeRatio * 22;

    useEffect(() => {
        if (!canvasRef.current || !tags) {
            return;
        }

        // 由于未加载和无 tag 的情况下 img 都是 null，需要区分是否加载
        if (tags.length === 0) {
            canvasRef.current.style.width = `1px`;
            canvasRef.current.style.height = `1px`;
            canvasRef.current.width = 1;
            canvasRef.current.height = 1;
            setImage(canvasRef.current.toDataURL());
            return;
        }

        const ctx = canvasRef.current.getContext('2d');
        ctx.font = `normal ${14 * resizeRatio}px Arial`;
        const [textLines, tagsHeight] = handleText(ctx, tags.join(', '), width, lineHeight);
        const titleMarginBottom = 4 * resizeRatio;
        const height =
            lineHeight // 第一行高度
            + titleMarginBottom // 第一行 margin-bottom
            + tagsHeight + 4 // 标签区域高度

        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        ctx.font = `bold ${15 * resizeRatio}px Arial`;
        ctx.fillStyle = '#3A59C6';
        ctx.fillText(
            title,
            0,
            lineHeight,
        );
        let currentHeight = lineHeight + titleMarginBottom;

        ctx.font = `normal ${14 * resizeRatio}px Arial`;
        ctx.fillStyle = '#323232';
        textLines.forEach((line, i) => {
            ctx.fillText(
                line,
                0,
                currentHeight + (i + 1) * lineHeight
            );
        });
        setImage(canvasRef.current.toDataURL());
    }, [image, lineHeight, resizeRatio, tags, title, width])

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}

// 绘制指定宽高的网格圆角矩形，由于存在网格的设计，会补全一定的高度
export const useBoard = (width, height, resizeRatio) => {
    const [image, setImage] = useState(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        if (!canvasRef.current || !width || !height) {
            return;
        }
        const ctx = canvasRef.current.getContext('2d');
        const strokeWidth = 4 * resizeRatio;
        const whiteBoardWidth = width - 2 * strokeWidth;
        // 绘制竖向 11 根分割线，以此为基础绘制网络
        const gutter = whiteBoardWidth / 12;
        const rows = (Math.floor(height / gutter) + 1);
        const whiteBoardHeight = rows * gutter
        const borardHeight = whiteBoardHeight + 2 * strokeWidth;

        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${borardHeight}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = borardHeight;

        // 边框渐变圆角矩形
        const gradient = ctx.createLinearGradient(
            0,
            borardHeight,
            width,
            0
        );
        gradient.addColorStop(0, '#F08200');
        gradient.addColorStop(1, '#3A59C6');
        fillRoundRect(
            ctx,
            0,
            0,
            width,
            borardHeight,
            12,
            gradient
        );
        // 白色圆角矩形
        fillRoundRect(
            ctx,
            strokeWidth,
            strokeWidth,
            whiteBoardWidth,
            whiteBoardHeight,
            6,
            '#fff'
        );

        // 绘制网格
        ctx.strokeStyle = "#F8F8F8";
        for (let i = 1; i <= 11; i++) {
            ctx.beginPath();
            ctx.moveTo(i * gutter + strokeWidth, strokeWidth);
            ctx.lineTo(i * gutter + strokeWidth, whiteBoardHeight);
            ctx.stroke();
        }
        for (let i = 1; i < rows; i++) {
            ctx.beginPath();
            ctx.moveTo(strokeWidth, i * gutter + strokeWidth);
            ctx.lineTo(whiteBoardWidth, i * gutter + strokeWidth);
            ctx.stroke();
        }

        setImage(canvasRef.current.toDataURL());
    }, [height, image, resizeRatio, width]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}

// 盖章
export const useStamp = (resizeRatio, year, month, date) => {
    const [image, setImage] = useState(null);
    const canvasRef = useRef(null);

    // 2x
    const stampImg = useReadImage('https://staticcdn.boyuai.com/materials/2020/12/18/oKTTyWxyHfdbiIcRlVFxp.png');
    const width = (stampImg?.width || 0) / 2 * resizeRatio
    const height = (stampImg?.height || 0) / 2 * resizeRatio

    useEffect(() => {
        if (!canvasRef.current || !year || !month || !date || !stampImg) {
            return;
        }
        const ctx = canvasRef.current.getContext('2d');
        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        ctx.drawImage(
            stampImg,
            0,
            0,
            width,
            height
        )

        // 日期
        ctx.textAlign = 'center'
        ctx.fillStyle = '#323232';
        ctx.font = `normal ${16 * resizeRatio}px Arial`;
        ctx.fillText(
            `${year}年${month}月${date}日`,
            stampImg.width / 2 - 24,
            stampImg.height / 2,
        );
        let currentHeight = stampImg.height / 2 + 40
        ctx.fillText(
            '博小鱼编程',
            stampImg.width / 2 + 10,
            currentHeight,
        );

        setImage(canvasRef.current.toDataURL());
    }, [date, height, image, month, resizeRatio, stampImg, width, year]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
