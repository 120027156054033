import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LessonReport from '../components/learn-lesson-report/lesson-report';
import './learn-lesson-report.less';
import useLearnLessonReportData from '../hooks/learn-lesson-report-data';
import LessonReportExperience from '../components/learn-lesson-report/experience';
import LessonReportExperience1 from '../components/learn-lesson-report/experience-1';
import LessonReportExperience1v202106 from '../components/learn-lesson-report/experience-1-202106';
import LessonReportExperience1v202108 from '../components/learn-lesson-report/experience-1-202108';
import LessonReportExperience2 from '../components/learn-lesson-report/experience-2';
import LessonReportExperience3 from '../components/learn-lesson-report/experience-3';
import LessonReportExperience4 from '../components/learn-lesson-report/experience-4';
import LessonReportL0Formal1 from '../components/learn-lesson-report/L0-formal-1';
import LessonReportL0Formal2 from '../components/learn-lesson-report/L0-formal-2';
import { LessonReportL0Formal3 } from '../components/learn-lesson-report/L0-formal-3-2021CNY';
import ExamReportL0Experience from '../components/learn-exam-report/experience';

// const shareHints = [
//     'https://staticcdn.boyuai.com/materials/2020/05/28/1X1fGbCbx57h4HXXSSoln.png!png', // computer blue
//     'https://staticcdn.boyuai.com/materials/2020/06/06/LF1Vj_7ZSwrgeDfHaR4WJ.png!png', // cup green
//     'https://staticcdn.boyuai.com/materials/2020/06/06/tLFFFlLjEzFC5J9zLhwEh.png!png', // robot yellow
// ];
const shareHintsQBit = [
    'https://staticcdn.boyuai.com/materials/2020/07/04/srarIDJ1-tYunssI222eE.png!png', // computer blue
    'https://staticcdn.boyuai.com/materials/2020/07/04/7AX3zsa9mpllY8L1cvxp-.png!png', // cup green
    'https://staticcdn.boyuai.com/materials/2020/07/04/Hihp0LN8zLN-EjXAwYvZv.png!png', // robot yellow
];

export default withRouter(function LearnReport(props) {
    const { token, lessonId } = props.match.params;
    const [height, setHeight] = useState(0);

    const reportData = useLearnLessonReportData(token, lessonId);
    // if (reportData) reportData.typeIndex = 0; // 查看各种情况下的海报样子用
    // if (reportData) reportData.posterType = 'experience-1-202106'; // 查看各种情况下的海报样子用
    // if (reportData) reportData.posterTypeV2 = 'experience-1-202108'; // 查看各种情况下的海报样子用

    const setShareHintHeight = useCallback(() => {
        let height = 0;
        const clientWidth =
            document.body.clientWidth > 450 ? 450 : document.body.clientWidth;
        if (document.body.clientHeight / clientWidth > 1624 / 750) {
            height = (document.body.clientHeight * 750) / clientWidth - 1624;
        }
        setHeight((clientWidth / 750) * (100 + height));
    }, []);
    useEffect(() => {
        setShareHintHeight();
        window.addEventListener('resize', setShareHintHeight);
        return () => window.removeEventListener('resize', setShareHintHeight);
    }, [setShareHintHeight]);
    if (!reportData) {
        return null;
    }
    if (reportData.posterTypeV2) {
        console.log('尝试启用新版渲染逻辑');
    }
    if (reportData.posterType?.startsWith('experience-0') || reportData.posterTypeV2?.startsWith('experience-0')) {
        let regResult = /^experience-0:(.*)/.exec(reportData.posterType);
        if (reportData.posterTypeV2) {
            regResult = /^experience-0:(.*)/.exec(reportData.posterTypeV2);
        }
        const version = regResult ? regResult[1] : null;
        let config;
        switch (version) {
            case 'v3':
                config = {
                    version: 3,
                    startDate: '周末',
                };
                break;
            case 'v2':
                config = {
                    version: 2,
                    startDate: '周末',
                };
                break;
            case 'v1':
                config = {
                    version: 1,
                    startDate: '12月27日（周日）',
                };
                break;
            default:
                config = {
                    version: 0,
                    startDate: '11月27日（周五）',
                };
        }
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                    config={config}
                />
            </div>
        );
    } else if (reportData.posterType === 'experience-1') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience1
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'experience-1-202106' || reportData.posterTypeV2 === 'experience-1-202106') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience1v202106 token={token} reportData={reportData} />
            </div>
        );
    } else if (reportData.posterTypeV2 === 'experience-1-202108') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience1v202108 token={token} reportData={reportData} />
            </div>
        );
    } else if (reportData.posterType === 'experience-2') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience2
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'experience-3') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience3
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'experience-4') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportExperience4
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'L0-formal-1') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportL0Formal1
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'L0-formal-2') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportL0Formal2
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                />
            </div>
        );
    } else if (reportData.posterType === 'L0-formal-3-2021CNY') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <LessonReportL0Formal3
                    token={token}
                    reportData={reportData}
                />
            </div>
        );
    } else if (reportData.posterType === 'L0-experience-exam-2020') {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <ExamReportL0Experience {...props} />
            </div>
        );
    }

    return (
        <div
            style={{
                minHeight: '100vh',
                backgroundColor: '#fff',
            }}
        >
            <LessonReport
                token={token}
                reportData={reportData}
                width={750}
                height={1624}
            >
                <div
                    className="learn-report-share-hint"
                    style={{ height: `${height}px` }}
                >
                    <img
                        src={shareHintsQBit[reportData.typeIndex]}
                        alt=""
                        width={'100%'}
                    />
                </div>
            </LessonReport>
        </div>
    );
});
