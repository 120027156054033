import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Main from '../components/main';
import useQRCode from '../hooks/qrcode';
import TagCloud from '../components/tag-cloud';
import './register.css';

const numberIcons = [
    'https://staticcdn.boyuai.com/materials/2019/11/30/Y-SMrvU1syZ_ncsGYEb-2.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/CD8aDTvsFawi604mscsCF.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/yJjs7opRYBBVdhmciDzNz.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/Z8vopom7AvFFyyuHT4pGC.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/nKankCaFTS6MzPW375ovw.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/ZFav5EuvAcYiAFi8HcBxR.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/tHDyYCvzTvp0Xjd47Zn67.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/T4HXXOhelERF9HUKuLa5q.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/kWgTIy7YdSO2w8FrOIF-m.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/3_Ud9LilbNUOJA8H80AUU.jpg',
    'https://staticcdn.boyuai.com/materials/2019/11/30/BvMqlEv2oFV9hWNxU38ps.jpg',
];

// const defaultLessons = ['暂无购买'];
const defaultLessons = [
    '课程导论',
    '人工智能',
    '数据科学',
    '机器学习简介',
    '机器学习应用',
    '机器学习基本思想',
    '模型选择',
    '模型泛化性',
    '判别模型与生成模型',
    '普适逼近定理',
    '反向传播算法介绍',
    '反向传播算法示例',
    '激活函数与损失函数',
    '深度学习思想简介',
    '梯度消失问题的解决方法',
    '陷入局部最小的解决方法',
    '深度学习中的正则化',
    '集成学习概念及应用',
    '集成学习的组合模型',
    'Bagging算法',
    'Bagging算法有效性分析',
    '随机森林',
    '广义加性模型',
    'AdaBoost简介',
    '提升算法简史',
    '个性化推荐简介',
    'K近邻算法',
    '矩阵分解的协同过滤算法',
    '马尔科夫网络简介',
    '马尔科夫网络应用示例',
    '马尔科夫网络vs.贝叶斯网络',
    '链式模型推断',
    '树图模型推断',
    '无监督学习简介',
    'K-means聚类',
    '主成分分析',
    '混合高斯模型的EM算法',
    '通用EM算法',
    '限制玻尔兹曼机简介',
    '限制玻尔兹曼机学习算法',
    '深度信念网络',
    '自动编码器',
    '学习理论',
    '偏差-方差分解',
    '假设空间ERM边界',
    'VC维',
    '时序差分学习',
    'SARSA',
    'Q-learning',
    '蒙特卡洛方法',
    '蒙特卡洛价值预测',
    '模型无关控制方法',
    '重要性采样',
    '参数化值函数近似',
    '状态值函数与状态-动作值函数近似',
    '策略梯度',
    'Actor-Critic',
    '深度强化学习',
    '深度Q网络',
    '信任区域策略优化TRPO',
    'A3C',
    '确定性策略梯度',
    '深度确定性策略梯度',
    '模仿学习',
    '行为克隆',
    '逆强化学习',
    '生成对抗模仿学习',
    '参数化动作空间',
    '多智能体强化学习',
    '随机博弈',
    '纳什均衡',
    '纳什Q-learning',
    '多任务学习',
    '深度多任务学习',
    '实例迁移',
    '特征迁移',
    '参数迁移',
    '深度实例迁移',
    '深度特征迁移',
    '深度参数迁移',
    '元学习',
    '自动机器学习与神经网络架构搜索',
    '机器学习的未来',
    '人工神经网络简介',
    '人工神经网络发展史',
    '线性回归',
    '梯度更新方式',
    '线性回归矩阵形式',
    '最大似然估计',
    '逻辑回归',
    '分类指标',
    '泛线性模型',
    '支持向量机简介',
    '支持向量机优化',
    '支持向量机核方法',
    '卷积神经网络',
    '循环神经网络',
    '长短期记忆网络',
    '长短期记忆网络使用案例',
    '决策树',
    'ID3决策树',
    'CART决策树',
    'GBDT梯度提升决策树',
    '深度学习的成功及深度森林',
    '多粒度级联森林',
    '学习排序介绍',
    '学习排序方法',
    '交叉验证',
    '特征选择',
    '强化学习简介',
    '马尔科夫决策过程',
    '基于动态规划的强化学习',
    '基于模型的强化学习',
    '基于协同过滤的推荐排序',
    '协同过滤深度模型',
    '贝叶斯网络',
    '概率图模型中的条件独立',
    '序列最小优化算法',
    '代码实践：线性回归',
    '代码实践：逻辑回归',
    '代码实践：支持向量机',
    '代码实践：反向传播算法和多层感知机',
    '代码实践：集成学习与随机森林',
    '代码实践：K近邻算法',
    '代码实践：矩阵分解',
    '代码实践：因子分解机',
    '代码实践：马尔科夫网络',
    '代码实践：K-means聚类',
    '代码实践：主成分分析',
    '代码实践：自动编码器',
    '代码实践：基于动态规划的强化学习',
    '代码实践：Q-learning和SARSA算法',
    '代码实践：策略梯度',
    '代码实践：深度Q网络',
    '代码实践：迁移学习',
    '生成式对抗网络',
    '代码实践：基于KNN的图像风格迁移',
    '代码实践：基于卷积神经网络的图像分类',
    '代码实践：基于像素梯度下降的图像风格迁移',
    '代码实践：决策树',
    '代码实践：GBDT梯度提升决策树',
];
const generateRandomLessons = () => {
    const items = defaultLessons.map((title) => ({
        title,
        number: Math.floor(Math.random() * 100),
    }));
    return items
        .sort((a, b) => a.number - b.number)
        .slice(0, 30)
        .map((o) => o.title);
};
const randomLessons = generateRandomLessons();

export default withRouter(function Register(props) {
    const [shareUrl, setShareUrl] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [name, setName] = useState(null);
    const qrcode = useQRCode(shareUrl);
    const [height, setHeight] = useState(0);
    const resolveRef = useRef(null);
    const qrcodeRef = useRef(null);
    const manRef = useRef(null);

    const setShareHintHeight = useCallback(() => {
        let height = 0;
        const clientWidth =
            document.body.clientWidth > 450 ? 450 : document.body.clientWidth;
        if (document.body.clientHeight / clientWidth > 1597 / 750) {
            height = (document.body.clientHeight * 750) / clientWidth - 1597;
        }
        setHeight((clientWidth / 750) * (142 + height));
    }, []);
    useEffect(() => {
        setShareHintHeight();
        window.addEventListener('resize', setShareHintHeight);
        return () => window.removeEventListener('resize', setShareHintHeight);
    }, [setShareHintHeight]);

    const { token } = props.match.params;

    const onUserInfoLoaded = (info) => {
        const site =
            process.env.REACT_APP_ENV === 'production'
                ? 'www.boyuai.com/elites'
                : 'dev.boyuai.com/course';
        setShareUrl(`https://${site}/register/${info.referralCode}`);
        setCreatedAt(info.createdAt);
        setLessons(
            info.public.lessons && info.public.lessons.length > 10
                ? info.public.lessons
                : randomLessons
        );
        setName(info.name);
    };

    const drawPortrait = (ctx, portraitImage, width) => {
        const portraitWidth = 74;
        const portraitX = 29;
        const portraitY = 1335;
        const centerX = portraitX + portraitWidth / 2;
        const centerY = portraitY + portraitWidth / 2;

        // 头像底框
        ctx.save();
        ctx.beginPath();
        ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.fillStyle = '#9DE7FF';
        ctx.fill();
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 5;
        ctx.stroke();
        ctx.restore();

        ctx.save();
        ctx.beginPath();
        ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(
            portraitImage,
            0,
            0,
            portraitImage.width,
            portraitImage.height,
            portraitX,
            portraitY,
            portraitWidth,
            portraitWidth
        );
        ctx.restore();
    };
    const drawInfo = (ctx, info) => {};
    const drawImages = (ctx, images) => {
        const [qrcode, man, ...numbers] = images;
        manRef.current = man;
        qrcodeRef.current = qrcode;

        let days =
            Math.floor((moment().unix() - moment(createdAt).unix()) / 86400) ||
            1; // 最小一天
        if (days < 30) {
            days = 30; // 最小30天
        }
        const digits = [
            numbers[Math.floor(days / 100)],
            numbers[Math.floor(days / 10) - Math.floor(days / 100) * 10],
            numbers[Math.floor(days % 10)],
            numbers[10],
        ];
        const fullWidth = digits.reduce((p, item) => p + item.width, 0) + 15;
        const ratio = 240 / fullWidth;
        let offset = 0;
        ctx.save();
        ctx.rotate(-0.08);
        digits.forEach((digit) => {
            ctx.drawImage(
                digit,
                0,
                0,
                digit.width,
                digit.height,
                30 + offset,
                240,
                digit.width * ratio,
                digit.height * ratio
            );
            offset += digit.width * ratio + 5;
        });
        ctx.restore();
    };
    const onTagCloudReady = useCallback(async (canvas) => {
        if (!resolveRef.current) {
            // 字云比图片先加载完毕，那么需要等一等
            await new Promise((resolve) => {
                const fetch = () => {
                    if (resolveRef.current) {
                        resolve();
                    } else {
                        setTimeout(fetch, 100);
                    }
                };
                fetch();
            });
        }
        resolveRef.current(canvas);
    }, []);
    const beforeEnding = useCallback(
        (ctx) => {
            return new Promise((resolve) => {
                resolveRef.current = (canvas) => {
                    ctx.save();
                    ctx.rotate(-0.08);
                    ctx.drawImage(
                        canvas,
                        0,
                        0,
                        canvas.width,
                        canvas.height,
                        50,
                        446,
                        canvas.width,
                        canvas.height
                    );
                    ctx.restore();
                    resolve();
                    const man = manRef.current;
                    ctx.drawImage(
                        man,
                        0,
                        0,
                        man.width,
                        man.height,
                        0,
                        1114,
                        man.width,
                        man.height
                    );

                    ctx.fillStyle = '#fff';
                    ctx.font = 'bold 36px Arial';
                    ctx.fillText(name || '', 121, 1385);

                    const qrcode = qrcodeRef.current;
                    ctx.drawImage(
                        qrcode,
                        0,
                        0,
                        qrcode.width,
                        qrcode.height,
                        24,
                        1482,
                        100,
                        100
                    );
                    ctx.fillStyle = '#fff';
                    ctx.font = 'normal 36px Arial';
                    ctx.fillText('扫码查看我给你的福利哦', 137, 1520);
                    ctx.font = 'normal 24px Arial';
                    ctx.fillText('我在伯禹学习平台等你', 137, 1560);
                };
            });
        },
        [name]
    );
    const generateShareQRCodeLink = (info) =>
        info &&
        info.public &&
        `https://www.boyuai.com/elites/register/${info.public.referralCode}`;
    return (
        <div
            style={{
                minHeight: '100vh',
                backgroundColor: '#fff',
            }}
        >
            <Main
                token={token}
                template="https://staticcdn.boyuai.com/materials/2019/11/30/_CH6Ce0fxzZWshZEL1qGZ.jpg"
                width={750}
                height={1597}
                drawPortrait={drawPortrait}
                drawInfo={drawInfo}
                drawImages={drawImages}
                generateShareQRCodeLink={generateShareQRCodeLink}
                images={[
                    qrcode,
                    'https://staticcdn.boyuai.com/materials/2019/12/02/p6pAi8UkFMUjt7WRxHS4t.jpg',
                    ...numberIcons,
                ]}
                onUserInfoLoaded={onUserInfoLoaded}
                beforeEnding={beforeEnding}
                backgroundColor="#2FB1F8"
            >
                {qrcode && (
                    <div
                        className="share-hint"
                        style={{ height: `${height}px` }}
                    >
                        <div className="qrcode">
                            <img
                                src={qrcode}
                                alt="二维码"
                                width="50"
                                height="50"
                            />
                        </div>
                        <div className="main">
                            <div>长按上方图片保存分享</div>
                            <div>邀请1位好友，每日解锁1个知识点</div>
                        </div>
                    </div>
                )}
            </Main>
            <div style={{ height: '0px', overflow: 'hidden' }}>
                <TagCloud items={lessons} onFinish={onTagCloudReady} />
            </div>
        </div>
    );
});
