import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Skeleton } from 'antd';
import useWxShare from '../../hooks/wx-share';
import useUserInfo from '../../hooks/user-info';
import useReadImage from '../../hooks/read-image';
import PropTypes from 'prop-types';
import useQRCode from '../../hooks/qrcode';
import moment from 'moment';
import '../main.css';
import { downloadPng } from '../../utils';
import { useRouteMatch } from 'react-router-dom';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const monospaceFont = isMobile ? 'Monospace' : 'Consolas';

// 总共有3种海报，伯禹默认、伯禹x量子位、伯禹x创行青年。其中“伯禹x创行青年”是特殊海报，绘制方式不同
const templateBoyu = {
    default:
        'https://staticcdn.boyuai.com/materials/2020/07/14/4bT5raYWOG3seMwihR0B4.png!png',
    mindMaster:
        'https://staticcdn.boyuai.com/materials/2020/07/14/g5Jmv3s3iaY77uOR-UUe6.png!png',
    codeMaster:
        'https://staticcdn.boyuai.com/materials/2020/07/14/jkXZY_fBp48t6ExLzOaXG.png!png',
};
// 量子位
const templateQBit = {
    default:
        'https://staticcdn.boyuai.com/materials/2020/07/01/SQNyh57RmRlLhAtJSpXiN.png!png',
    mindMaster:
        'https://staticcdn.boyuai.com/materials/2020/07/01/Y6OHVz-HplyfqFd_W0PPV.png!png',
    codeMaster:
        'https://staticcdn.boyuai.com/materials/2020/07/01/Hd_aXTn0O22iZ8tIy0xYS.png!png',
};

const getCodeMasterTemplate = (posterType) => {
    switch (posterType) {
        case 'q-bit-ai': // 量子位数据科学与AI实用课
        case 'q-bit-python': // 量子位python课
            return templateQBit.codeMaster;
        case '90min': // 90分钟编程挑战
        case 'bookfair':
        case 'tencent-summer2020':
        default:
            return templateBoyu.codeMaster;
    }
};
const getMindMasterTemplate = (posterType) => {
    switch (posterType) {
        case 'q-bit-ai': // 量子位数据科学与AI实用课
        case 'q-bit-python': // 量子位python课
            return templateQBit.mindMaster;
        case '90min': // 90分钟编程挑战
        case 'bookfair':
        case 'tencent-summer2020':
        default:
            return templateBoyu.mindMaster;
    }
};
const getDefaultTemplate = (posterType) => {
    switch (posterType) {
        case 'q-bit-ai': // 量子位数据科学与AI实用课
        case 'q-bit-python': // 量子位python课
            return templateQBit.default;
        case '90min': // 90分钟编程挑战
        case 'bookfair':
        case 'tencent-summer2020':
        default:
            return templateBoyu.default;
    }
};

const drawCodeOutput = (ctx, codeOutput, width, height) => {
    let ratio = codeOutput.width / codeOutput.height; // 按照原图比例
    let imageHeight = 0.15 * height; // 最大高度
    let imageWidth = 0.6 * width; // 最大宽度
    if (imageWidth / imageHeight < ratio) {
        ctx.drawImage(
            codeOutput,
            (width - imageWidth) / 2,
            0.65 * height,
            imageWidth,
            imageWidth / ratio
        );
    } else {
        ctx.drawImage(
            codeOutput,
            (width - ratio * imageHeight) / 2,
            0.65 * height,
            ratio * imageHeight,
            imageHeight
        );
    }
};

const drawInvitation = (ctx, name, width, height, posterType) => {
    switch (posterType) {
        case 'bookfair':
            // 书展
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `掌握Python与AI，做智能时代的创造者`,
                width * 0.03,
                height * 0.987
            );
            return;
        case 'tencent-summer2020':
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(`掌握AI，主宰智能时代`, width * 0.03, height * 0.987);
            return;
        case 'q-bit-ai':
            // 量子位数据科学与AI实用课
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `在人工智能学习路上，奠基启航`,
                width * 0.03,
                height * 0.987
            );
            return;
        case 'q-bit-python':
            // 量子位python课
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `掌握Python，开启你的智能时代`,
                width * 0.03,
                height * 0.987
            );
            return;
        case '90min':
            // 90分钟编程挑战
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `从零开始，90分钟玩转Python`,
                width * 0.03,
                height * 0.987
            );
            return;
        default:
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `掌握Python与AI，做智能时代的创造者`,
                width * 0.03,
                height * 0.987
            );
            return;
    }
};

const drawCourseTitle = (ctx, time, courseTitle, width, height) => {
    const fontSize = height * 0.02;
    const titleX = width * 0.095;
    const titleY = height * 0.386;
    const finishY = height * 0.356;
    ctx.fillStyle = '#544574';
    ctx.font = `normal ${Math.floor(fontSize)}px Arial`;
    ctx.fillText(
        `于${moment(time).format('YYYY年MM月DD日')}完成课程`,
        titleX,
        finishY
    );

    courseTitle = `《${courseTitle}》`;
    ctx.fillStyle = '#0884FE';
    ctx.textAlign = 'left';
    ctx.font = `normal ${Math.floor(fontSize)}px Arial`;
    ctx.fillText(`${courseTitle}`, titleX - Math.floor(fontSize * 0.3), titleY);
};

const drawDisplayCode = (ctx, displayCode, width, height) => {
    const codeX = width * 0.1;
    const codeY = height * 0.452;
    const codeYStep = height * 0.018;
    const fontSize = height * 0.0125;
    const displayCodeList = displayCode
        .split('\n')
        .filter((str) => !str.startsWith('# 【'))
        .filter((str) => !str.trim().startsWith('#'));
    const displayCodeListWithoutContinuousEmptyLines = [];
    let prevLineIsEmpty = true;
    for (const line of displayCodeList) {
        if (!line.trim()) {
            // 是空行
            if (!prevLineIsEmpty) {
                // 前一行不空
                prevLineIsEmpty = true;
            } else {
                // 前一行空
                continue;
            }
        } else {
            // 不是空行
            prevLineIsEmpty = false;
        }
        displayCodeListWithoutContinuousEmptyLines.push(line);
    }
    // const displayCodeList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    ctx.fillStyle = '#3A3A3A';
    ctx.textAlign = 'left';
    ctx.font = `bold ${Math.floor(fontSize)}px ${monospaceFont}`;
    displayCodeListWithoutContinuousEmptyLines
        .slice(0, 10)
        .forEach((line, i) => {
            let textWidth = ctx.measureText(line).width;
            while (textWidth > width * 0.8) {
                textWidth = ctx.measureText(line).width;
                line = line.slice(0, line.length - 1);
            }
            ctx.fillText(`${line}`, codeX, codeY + i * codeYStep);
        });
};

const drawReport = (ctx, reportData, width, height) => {
    const reportTitleX1 = width * 0.3;
    const reportTitleX2 = width * 0.7;
    const reportTitleY = height * 0.846;
    const reportTitleFontSize = Math.floor(height * 0.015);
    const reportDataY = height * 0.9;
    const reportDataFontSizeLeft = Math.floor(height * 0.03); // 数字
    const reportDataFontSizeRight = Math.floor(height * 0.014); // 单位
    const { codeLines, studyDuration } = reportData;
    ctx.fillStyle = '#BEE8FF';
    ctx.textAlign = 'center';
    ctx.font = `normal ${reportTitleFontSize}px Arial`;
    ctx.fillText(`学习时长`, reportTitleX1, reportTitleY);
    ctx.fillText(`累计编写代码`, reportTitleX2, reportTitleY);
    ctx.fillStyle = '#ffffff';

    // 学习时长
    const studyDurationText =
        studyDuration >= 10000 ? `1万+` : `${studyDuration}`; // 超过10000显示1万+
    // 代码行数
    const codeLinesText = codeLines >= 1000 ? `1千+` : `${codeLines}`; // 超过1000显示1千+

    // 计算宽度
    ctx.font = `bold ${reportDataFontSizeLeft}px Arial`;
    let dataTextWidth1 = ctx.measureText(studyDurationText).width;
    let dataTextWidth2 = ctx.measureText(codeLinesText).width;
    ctx.font = `bold ${reportDataFontSizeRight}px Arial`;
    dataTextWidth1 += ctx.measureText('分钟').width;
    dataTextWidth2 += ctx.measureText('行').width;

    ctx.font = `bold ${reportDataFontSizeLeft}px Arial`;
    ctx.textAlign = 'left';
    ctx.fillText(
        studyDurationText,
        reportTitleX1 - dataTextWidth1 / 2,
        reportDataY
    );
    ctx.fillText(
        codeLinesText,
        reportTitleX2 - dataTextWidth2 / 2,
        reportDataY
    );
    ctx.font = `bold ${reportDataFontSizeRight}px Arial`;
    ctx.textAlign = 'right';
    ctx.fillText('分钟', reportTitleX1 + dataTextWidth1 / 2, reportDataY);
    ctx.fillText('行', reportTitleX2 + dataTextWidth2 / 2, reportDataY);
    ctx.textAlign = 'left'; // 还原
};

const drawUserInfo = (ctx, portrait, name, width, height) => {
    // portrait
    let portraitWidth = 0.12 * width;
    let portraitX = width * 0.095;
    let portraitY = 0.242 * height;
    let centerX = portraitX + portraitWidth / 2;
    let centerY = portraitY + portraitWidth / 2;
    // name
    let nameX = width * 0.095;
    let nameY = 0.326 * height;
    let nameLength = 0.8 * width;
    let nameColor = '#544574';
    let nameFont = `bold ${Math.floor(height * 0.028)}px Arial`;

    // 头像底框
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fillStyle = '#ffffff';
    ctx.fill();
    ctx.strokeStyle = '#0884FE';
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(
        portrait,
        0,
        0,
        portrait.width,
        portrait.height,
        portraitX,
        portraitY,
        portraitWidth,
        portraitWidth
    );
    ctx.restore();

    // name
    ctx.fillStyle = nameColor;
    ctx.fillOpacity = 0.65;
    ctx.font = nameFont;
    let displayName = name; // 最后展示的名字
    while (ctx.measureText(displayName).width > nameLength) {
        displayName = `${name.slice(0, displayName.length - 2)}`;
    }
    ctx.fillText(
        `${displayName}${displayName.length < name.length ? '..' : ''}`,
        nameX,
        nameY
    );
};

function CourseReport(props) {
    const { token, reportData, width, height } = props;
    const match = useRouteMatch(
        '/learn-course-report/:token/:courseId/download'
    );
    const download = match && match.isExact;
    useWxShare({
        title: reportData && `我学习了《${reportData.courseTitle}》课程`,
        desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
        link: null,
    });

    // 成就判定
    const templateWithAchievement = useMemo(() => {
        if (!reportData) {
            return null;
        }
        // 代码宗师，第一优先级
        if (reportData.achievement === 'CODE_MASTER') {
            return getCodeMasterTemplate(reportData.posterType);
        }
        // 头脑王者，第二优先级——quiz正确率>80%
        if (reportData.achievement === 'MIND_MASTER') {
            return getMindMasterTemplate(reportData.posterType);
        }
        // 默认海报
        return getDefaultTemplate(reportData.posterType);
    }, [reportData]);
    const userInfo = useUserInfo(
        token,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );
    const template = useReadImage(templateWithAchievement);
    const reportDataBg = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/06/07/FuMvdvX0WOjtLp2x5kKXp.png!png'
    );
    const codeOutput = useReadImage(
        reportData && reportData.materials?.displayImage
    );
    // 开发用——最大画布
    // const codeOutput = useReadImage(
    //     'https://static.dev.boyuai.com/public/result/371/2020/08/26/Eh1sHkwhbZ1pa6keJL8KF.jpg'
    // );

    // 有userInfo但是没有userInfo.portrait说明用户没有上传头像，故用默认头像
    const portrait = useReadImage(userInfo && userInfo.portrait);
    const qrcode = useReadImage(
        useQRCode(
            reportData && reportData.shareLink ? reportData.shareLink : null
        )
    );
    const canvasRef = useRef(null);
    const context = useRef(null);

    const [image, setImage] = useState(null);

    useEffect(() => {
        /*console.log(
            template,
            reportDataBg,
            codeOutput,
            userInfo,
            portrait,
            canvasRef.current,
            qrcode,
            reportData
        );*/
        if (
            !template ||
            !reportDataBg ||
            !codeOutput ||
            !userInfo ||
            !portrait ||
            !canvasRef.current ||
            !qrcode ||
            !reportData
        ) {
            return;
        }
        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        // 创建ctx
        context.current = canvasRef.current.getContext('2d');
        // 背景
        context.current.drawImage(template, 0, 0, width, height);
        // 标题
        drawCourseTitle(
            context.current,
            reportData.time,
            reportData.courseTitle,
            width,
            height
        );
        // 代码
        drawDisplayCode(
            context.current,
            reportData.materials?.displayCode,
            width,
            height
        );
        // context.current.drawImage(
        //     reportDataBg,
        //     0.61 * width,
        //     0.32 * height,
        //     0.373 * width,
        //     0.1 * height
        // );
        // 数据展示
        drawReport(context.current, reportData, width, height);
        // 个人信息
        drawUserInfo(
            context.current,
            portrait,
            `${userInfo.name || '该'}同学`,
            width,
            height
        );
        drawCodeOutput(context.current, codeOutput, width, height);
        drawInvitation(
            context.current,
            userInfo.name,
            width,
            height,
            reportData.posterType
        );
        // 二维码
        context.current.drawImage(
            qrcode,
            width * 0.875,
            height * 0.943,
            width * 0.11,
            width * 0.11
        );

        // 个人信息
        // drawUserInfo(context.current, portrait, nickName, qrcode, width, height, reportData.typeIndex % 3);
        if (download) {
            downloadPng(
                canvasRef.current.toDataURL(),
                `${reportData.courseTitle}-${
                    userInfo.name ? userInfo.name : '伯禹学员'
                }`
            );
        }
        setImage(canvasRef.current.toDataURL());
    }, [
        height,
        width,
        template,
        reportDataBg,
        codeOutput,
        userInfo,
        portrait,
        qrcode,
        reportData,
        download,
    ]);

    if (!image) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result" style={props.pageStyle}>
            <div className="main-image">
                <img src={image} alt="" />
            </div>
            {props.children}
        </div>
    );
}

CourseReport.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
};

export default CourseReport;
