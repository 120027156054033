import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { drawReport } from '../../../utils/poster';


// 学习时长、累计代码、quiz正确率、自主完成率等
// 输入的宽高都是原尺寸，在内部乘resizeRatio
export default function useDataBlock(width, height, resizeRatio, reportData) {
    width = width * resizeRatio;
    height = height * resizeRatio;
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);

    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && reportData) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;

            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${height}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = height;

            // 计算高度
            drawReport(ctx, reportData, width, height, {
                titleColor: '#3A59C6',
                titleFontStyle: `normal  ${16 * resizeRatio}px Arial`,
                bodyDigitColor: '#F08200',
                bodyDigitFontStyle: `bold ${32 * resizeRatio}px Arial`,
                bodyUnitColor: '#F08200',
                bodyUnitFontStyle: `normal ${16 * resizeRatio}px Arial`,
            });

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [width, height, reportData, image, resizeRatio]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
