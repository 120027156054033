// 总共有三种海报，伯禹默认、伯禹x量子位、伯禹x创行青年
// 本文件是伯禹x创行青年的特殊海报

import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Skeleton } from 'antd';
import useWxShare from '../../../hooks/wx-share';
import useUserInfo from '../../../hooks/user-info';
import useReadImage from '../../../hooks/read-image';
// import useQRCode from '../../../hooks/qrcode';
import '../../main.css';
import { downloadPng } from '../../../utils';
import { useRouteMatch } from 'react-router-dom';
import useCodeBlock from './code-block';
import useAnalysisBlock from './analysis-block';
import useSummaryBlock from './summary-block';

export default function CourseReportYEAction(props) {
    const { token: publicToken, reportData, width } = props;

    // 素材
    const quizLogContent = reportData?.materials?.displayQuiz?.content;
    const displayImages = useMemo(() => {
        const list = [
            reportData?.materials?.displayImage,
            reportData?.materials?.displayImage1,
            reportData?.materials?.displayImage2,
        ];
        if (list.every(Boolean)) {
            return list;
        } else {
            return null;
        }
    }, [reportData]);
    const codeList = useMemo(() => {
        const list = [
            reportData?.materials?.displayCode,
            reportData?.materials?.displayCode1,
            reportData?.materials?.displayCode2,
        ];
        if (list.every(Boolean)) {
            return list;
        } else {
            return null;
        }
    }, [reportData]);

    const resizeRatio = width / 414; // 设计稿宽度是414
    const height = 4000;
    const match = useRouteMatch(
        '/learn-course-report/:publicToken/:courseId/download'
    );
    const download = match && match.isExact;

    useWxShare(
        reportData && {
            title: `我学习了《${reportData.courseTitle}》课程`,
            desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
            link: null,
        }
    );
    // console.log(reportData);

    // 用户信息
    const userInfo = useUserInfo(
        publicToken,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );
    const name = quizLogContent && quizLogContent['fill-1'][0];

    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);

    // 海报图
    const [poster, setPoster] = useState(null);

    const titleAndBackground = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/10/28/xWjQCXoPqAKaoR5OumHMk.png!png'
    );

    // 代码流程
    const [codeBlock, codeBlockCanvas] = useCodeBlock(
        width,
        resizeRatio,
        codeList
    );

    // 可视化分析
    const textList = useMemo(() => {
        if (quizLogContent) {
            return [
                quizLogContent['fill-2'][0],
                quizLogContent['fill-3'][0],
                quizLogContent['fill-4'][0],
            ];
        } else {
            return [];
        }
    }, [quizLogContent]);
    const [analysisBlock, analysisBlockCanvas] = useAnalysisBlock(
        width,
        resizeRatio,
        displayImages,
        textList
    );

    // 总结
    const [summaryBlock, summaryBlockCanvas] = useSummaryBlock(
        width,
        resizeRatio,
        quizLogContent &&
            quizLogContent['fill-5'] &&
            quizLogContent['fill-5'][0]
    );

    // 二维码及引导
    const hint = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/10/29/PlIjCuTbTqClr1fwNVt0B.png!png'
    );

    useEffect(() => {
        if (poster) {
            // 画完不用再画
            return;
        }
        /*console.log(
            canvasRef.current,
            titleAndBackground,
            userInfo,
            name,
            codeBlock,
            analysisBlock,
            summaryBlock,
            hint,
            reportData
        );*/
        if (
            canvasRef.current &&
            titleAndBackground &&
            userInfo &&
            name &&
            codeBlock &&
            analysisBlock &&
            summaryBlock &&
            hint &&
            reportData
        ) {
            const height =
                795 * resizeRatio +
                codeBlock.height +
                analysisBlock.height +
                summaryBlock.height +
                3 * 31 +
                108 * resizeRatio;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${height}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;

            // 画布背景颜色
            ctx.fillStyle = '#2F318A';
            ctx.fillRect(0, 0, width, height);

            // 大标题和背景
            ctx.drawImage(
                titleAndBackground,
                0,
                0,
                width,
                (titleAndBackground.height / titleAndBackground.width) * width
            );

            // 分析人
            ctx.fillStyle = '#E44BA7';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.font = `bold ${Math.floor(16 * resizeRatio)}px Arial`;
            const displayName =
                name.length > 10 ? `${name.slice(0, 10)}...` : name;
            ctx.fillText(
                `分析人 丨 ${displayName}`,
                width / 2,
                (222 + 37 / 2) * resizeRatio
            );

            // 代码
            const codeBlockY = 795 * resizeRatio;
            ctx.drawImage(
                codeBlock,
                0,
                codeBlockY,
                width,
                (codeBlock.height / codeBlock.width) * width
            );

            // 可视化分析
            const analysisBlockY = codeBlockY + codeBlock.height + 31;
            ctx.drawImage(
                analysisBlock,
                0,
                analysisBlockY,
                width,
                (analysisBlock.height / analysisBlock.width) * width
            );

            // 总结
            const summaryBlockY = analysisBlockY + analysisBlock.height + 31;
            ctx.drawImage(
                summaryBlock,
                0,
                summaryBlockY,
                width,
                (summaryBlock.height / summaryBlock.width) * width
            );

            // 二维码和引导
            const hintY = summaryBlockY + summaryBlock.height + 31;
            ctx.drawImage(
                hint,
                0,
                hintY,
                width,
                (hint.height / hint.width) * width
            );

            // 下载
            if (download) {
                downloadPng(
                    canvasRef.current.toDataURL(),
                    `${reportData.courseTitle}-${
                        userInfo.name ? userInfo.name : '伯禹学员'
                    }`
                );
            }
            // 生成image
            setPoster(canvasRef.current.toDataURL());
        }
    }, [
        poster,
        width,
        height,
        resizeRatio,
        userInfo,
        name,
        reportData,
        titleAndBackground,
        codeBlock,
        analysisBlock,
        summaryBlock,
        hint,
        download,
    ]);

    if (!poster) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                {codeBlockCanvas}
                {analysisBlockCanvas}
                {summaryBlockCanvas}
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result" style={props.pageStyle}>
            <div className="main-image">
                <img src={poster} alt="" />
            </div>
            {props.children}
        </div>
    );
}
