import { useEffect } from 'react';
import { remote } from '@boyuai/utils';
import wx from 'weixin-js-sdk';

export default function useWxShare(options) {
    useEffect(() => {
        if (process.env.REACT_APP_ENV !== 'production') {
            return;
        }
        if (!options) {
            return;
        }
        const {
            title = '伯禹人工智能学院',
            desc = null,
            link = null,
        } = options;
        const shareLink = link || window.location.href;
        remote
            .$post(`/common/jsconfig`, {
                param: {
                    debug: false,
                    jsApiList: [
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'chooseImage',
                        'previewImage',
                        'uploadImage',
                        'downloadImage',
                    ],
                    url: shareLink,
                },
            })
            .then((param) => {
                // console.log('param', param);
                // wx.checkJsApi({
                //     jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                //     success: function (res) {
                //         console.log(1231213123, res);
                //         // 以键值对的形式返回，可用的api值true，不可用为false
                //         // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                //     },
                // });
                wx.config(param);
                wx.onMenuShareTimeline({
                    title,
                    desc,
                    link: shareLink,
                    imgUrl:
                        'https://staticcdn.boyuai.com/materials/2020/09/18/1349VlaZ8jZdFyMH1XMSy.png!png',
                });
                wx.onMenuShareAppMessage({
                    title,
                    desc,
                    link: shareLink,
                    imgUrl:
                        'https://staticcdn.boyuai.com/materials/2020/09/18/1349VlaZ8jZdFyMH1XMSy.png!png',
                });
                wx.ready(function () {
                    wx.updateAppMessageShareData({
                        title,
                        desc,
                        link: shareLink,
                        imgUrl:
                            'https://staticcdn.boyuai.com/materials/2020/09/18/1349VlaZ8jZdFyMH1XMSy.png!png',
                    });
                    wx.updateTimelineShareData({
                        title,
                        desc,
                        link: shareLink,
                        imgUrl:
                            'https://staticcdn.boyuai.com/materials/2020/09/18/1349VlaZ8jZdFyMH1XMSy.png!png',
                    });
                });
            });
    }, [options]);
}
