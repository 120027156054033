import { getPercentage } from './index';

export const calcDisplayItems = (reportData) => {
    const {
        quizAccuracy,
        codeLines,
        accumulatedCodeLines,
        selfComplete,
        studyDuration,
    } = reportData;

    // const codeLines = 10000;
    // const studyDuration = 10000;
    // const accumulatedCodeLines = 1000;
    // const selfComplete = [8, 9];
    // const quizAccuracy = [7, 9];

    // 显示项目
    let displayItems = [];
    // 学习时长项，必有
    const studyDurationItem = {
        title: '学习时长',
        data: studyDuration > 9999 ? '1万+' : studyDuration,
        unit: '分钟',
    };
    // 代码行数项
    const translateCodeLines = (value) =>
        Math.floor(value / 1000)
            ? `${
                  Math.floor(value / 1000) > 9 ? 9 : Math.floor(value / 1000)
              }千+`
            : value;
    const codeLinesItem = {
        title: accumulatedCodeLines ? '累计编写代码' : '编写代码',
        data: accumulatedCodeLines
            ? translateCodeLines(accumulatedCodeLines)
            : translateCodeLines(codeLines),
        unit: '行',
    };
    // quiz正确率项
    const quizAccuracyItem =
        getPercentage(quizAccuracy[0], quizAccuracy[1]) >= 0.8
            ? {
                  // 不低于0.8，百分数
                  title: 'Quiz正确率',
                  data: getPercentage(quizAccuracy[0], quizAccuracy[1]) * 100,
                  unit: '%',
              }
            : {
                  // 低于0.8，小数
                  title: 'Quiz正确率',
                  data: quizAccuracy[0],
                  unit: `/${quizAccuracy[1]}`,
              };
    // 自主完成率项
    const selfCompleteItem =
        getPercentage(selfComplete[0], selfComplete[1]) >= 0.8
            ? {
                  // 不低于0.8，百分数
                  title: '自主完成率',
                  data: getPercentage(selfComplete[0], selfComplete[1]) * 100,
                  unit: '%',
              }
            : {
                  // 低于0.8，小数
                  title: '自主完成率',
                  data: selfComplete[0],
                  unit: `/${selfComplete[1]}`,
              };
    // xx率项(从quiz和自主完成率里二选一)
    const rateItem =
        getPercentage(quizAccuracy[0], quizAccuracy[1]) >=
        getPercentage(selfComplete[0], selfComplete[1])
            ? quizAccuracyItem
            : selfCompleteItem;

    // 按照数据特点选择显示项
    if (accumulatedCodeLines === 0) {
        // 没有代码 => 学习时长 + quiz正确率/自主完成率
        displayItems = [studyDurationItem, rateItem];
    } else if (
        getPercentage(selfComplete[0], selfComplete[1]) < 0.6 &&
        getPercentage(quizAccuracy[0], quizAccuracy[1]) < 0.6
    ) {
        // 有代码 && 两个率都不到0.6 => 学习时长 + 编写代码
        displayItems = [studyDurationItem, codeLinesItem];
    } else {
        displayItems = [studyDurationItem, codeLinesItem, rateItem];
    }

    return displayItems;
}

// 绘制单元数据
export const drawReport = (
    ctx,
    reportData,
    width,
    height,
    {
        titleColor = '#767676', // 标题颜色
        titleFontStyle = `normal 32px Arial`,
        bodyDigitColor = '#767676', // 数据的数字颜色
        bodyDigitFontStyle = `bold 24px Arial`, // 数据的数字字体
        bodyUnitColor = '#767676', // 数据的单位颜色
        bodyUnitFontStyle = `bold 24px Arial`, // 数据的单位字体
    }
) => {
    const titleY = 0.05 * height;
    const bodyY = 0.95 * height;
    const reportX2Cols = [0.3 * width, 0.7 * width]; // 两列
    const reportX3Cols = [0.23 * width, 0.5 * width, 0.78 * width]; // 三列

    // 显示项目
    const displayItems = calcDisplayItems(reportData);
    // 选择三列还是两列
    const reportX = displayItems.length === 3 ? reportX3Cols : reportX2Cols;

    // 开始画画
    // 先写标题
    ctx.fillStyle = titleColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    displayItems.forEach((item, index) => {
        ctx.font = titleFontStyle;
        ctx.fillText(item.title, reportX[index], titleY);
    });
    // 再写数据
    ctx.textBaseline = 'alphabetic';
    displayItems.forEach((item, index) => {
        // 计算宽度,使组合后的文字能居中
        ctx.font = bodyDigitFontStyle;
        let dataTextWidth = ctx.measureText(item.data).width;
        ctx.font = bodyUnitFontStyle;
        dataTextWidth += ctx.measureText(item.unit).width;

        // 数字
        ctx.fillStyle = bodyDigitColor;
        ctx.font = bodyDigitFontStyle;
        ctx.textAlign = 'left';
        ctx.fillText(
            item.data,
            reportX[index] - dataTextWidth / 2, // x轴的位置是根据3项或者2项预设好的
            bodyY
        );
        // 单位
        ctx.fillStyle = bodyUnitColor;
        ctx.font = bodyUnitFontStyle;
        ctx.textAlign = 'right';
        ctx.fillText(
            item.unit,
            reportX[index] + dataTextWidth / 2, // x轴的位置是根据3项或者2项预设好的
            bodyY
        );
    });
    // 还原
    ctx.textAlign = 'left';
};

/**
 * 输入代码，返回一个数组，存放经过“去除注释”、“连续空行处理”之后的前n行代码
 *
 * @param code
 * @param maxLines
 * @param showAnnotation
 * @param language
 * @param filterFunction
 * @returns {*[]}
 */
export const handleCode = (
    code,
    {
        maxLines = 10,
        showAnnotation = false,
        language = 'python',
        filterFunction = () => true,
    }
) => {
    // console.log("code.split('\\n')", code.split('\n'));
    let displayCodeList = code.split('\n').filter(filterFunction);
    // console.log('displayCodeList', displayCodeList);
    if (!showAnnotation) {
        if (language === 'python') {
            displayCodeList = displayCodeList
                .filter((str) => !str.startsWith('# 【'))
                .filter((str) => !str.trim().startsWith('#'));
        } else if (language === 'c++') {
            displayCodeList = displayCodeList.filter(
                (str) => !str.trim().startsWith('//')
            );
        } else {
            throw new Error('unprocessable language');
        }
    }
    const displayCodeListWithoutContinuousEmptyLines = [];
    // 连续空行只保留一行，首行不能为空行
    let prevLineIsEmpty = true;
    for (const line of displayCodeList) {
        if (!line.trim()) {
            // 是空行
            if (!prevLineIsEmpty) {
                // 前一行不空
                prevLineIsEmpty = true;
            } else {
                // 前一行空
                continue;
            }
        } else {
            // 不是空行
            prevLineIsEmpty = false;
        }
        displayCodeListWithoutContinuousEmptyLines.push(line);
    }
    const finalCodes = displayCodeListWithoutContinuousEmptyLines.slice(
        0,
        maxLines
    );
    // console.log('finalCodes', finalCodes);
    return finalCodes;
};
