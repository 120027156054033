import { useState, useEffect } from "react";
import { loadImage } from '../utils';

export default function useReadImages(urls = []) {
  const [images, setImages] = useState(null);
  useEffect(() => {
    if (!urls || !urls.length || !urls.every(Boolean)) {
      return setImages(null);
    }
    Promise.all(urls.map(loadImage)).then(setImages);
  }, [urls]);
  return images;
}
