import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { autoWrapString, drawAspectFit, fillRoundRect } from '../../../utils';

// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// const monospaceFont = isMobile ? 'Monospace' : 'Consolas';
const monospaceFont = 'Consolas'; // 分析直接用这个

const drawTitle = (ctx, height, resizeRatio, title, ignoreDot) => {
    // 标题左侧的点
    if (!ignoreDot) {
        ctx.beginPath(); //开始绘制
        ctx.arc(
            (18 + 30 + 4) * resizeRatio,
            (height + 12) * resizeRatio,
            4 * resizeRatio,
            0,
            2 * Math.PI
        ); //arc 的意思是“弧”
        ctx.fillStyle = '#3A59C6'; //设置填充颜色
        ctx.fill(); //开始填充
        ctx.strokeStyle = '#3A59C6';
        ctx.stroke();
    }
    // 标题
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#3A59C6';
    ctx.font = `normal ${16 * resizeRatio}px Arial`;
    ctx.fillText(title, (18 + 53) * resizeRatio, (height + 12) * resizeRatio);
};
/**
 *
 * @param ctx
 * @param text
 * @param textYStep 每行递进高度，不带resizeRatio的
 * @param textWidth
 * @returns {(*[]|number)[]} [分行结果，行数 * 高度]
 */
const handleText = (ctx, text, textYStep, textWidth) => {
    const displayTextList =
        text.length > 250
            ? autoWrapString(ctx, `${text.slice(0, 250)}...`, textWidth)
            : autoWrapString(ctx, text, textWidth);
    return [displayTextList, displayTextList.length * textYStep];
};

const drawAnalysis = (ctx, height, resizeRatio, text) => {
    const rectX = (18 + 30) * resizeRatio;
    const rectY = height * resizeRatio;
    const codeX = (18 + 30 + 22) * resizeRatio;
    const codeY = (height + 17) * resizeRatio;
    const codeWidth = 274 * resizeRatio;
    const textYStep = 20 * resizeRatio;

    ctx.font = `normal ${14 * resizeRatio}px ${monospaceFont}`;
    const [finalTextList, textHeight] = handleText(
        ctx,
        text,
        20,
        274 * resizeRatio
    );
    const rectHeight = textHeight + 32;
    // 灰色框
    ctx.globalAlpha = 0.3;
    fillRoundRect(
        ctx,
        rectX,
        rectY,
        312 * resizeRatio,
        rectHeight * resizeRatio,
        10 * resizeRatio,
        '#F8D57E'
    );
    ctx.globalAlpha = 1;
    // 绘制分析
    ctx.fillStyle = '#F08200';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    finalTextList.forEach((line, i) => {
        let textWidth = ctx.measureText(line).width;
        while (textWidth > codeWidth) {
            textWidth = ctx.measureText(line).width;
            line = line.slice(0, line.length - 1);
        }
        ctx.fillText(`${line}`, codeX, codeY + i * textYStep);
    });
    return rectHeight;
};

export default function useStudyResultBlock(
    config,
    width,
    resizeRatio,
    target
) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/25/xzzT5pPlryY8PBnVxn2zn.png!png' // 任务目标
    );

    const fixedCode = useReadImage(
        config.version > 0
            ? 'https://staticcdn.boyuai.com/materials/2020/12/26/TRguqrSZ8UkNRrELnsz7K.png!png'
            : 'https://staticcdn.boyuai.com/materials/2020/11/25/sejcQk2Mtf83N5kYk7VTd.png!png'
    );

    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && title && target && fixedCode) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度
            // 白色块高度
            // 需要跟白色块的参数一样
            ctx.font = `normal ${14 * resizeRatio}px ${monospaceFont}`;
            const [, targetTextHeight] = handleText(
                ctx,
                target,
                20,
                274 * resizeRatio
            );
            const blockHeight = 176 + targetTextHeight + 12 * 3 + 32 * 3;
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + 27;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                41 * resizeRatio,
                currentHeight,
                104 * resizeRatio,
                27 * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += 27;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#F08200');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 分析1
            currentHeight += 12;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '我今天运行了我的第一段C++代码！'
            );
            drawAspectFit(
                ctx,
                fixedCode,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + 312) * resizeRatio,
                (currentHeight + 176) * resizeRatio
            );
            // 金色文字
            ctx.textBaseline = 'left';
            ctx.fillStyle = '#F08200';
            ctx.font = `bold ${16 * resizeRatio}px Arial`;
            ctx.fillText(
                '输入我的名字，博小鱼就会跟我打招呼啦～',
                55 * resizeRatio,
                (196 + 12) * resizeRatio
            );
            drawTitle(
                ctx,
                196 + 12 + 24,
                resizeRatio,
                '你希望通过学习编程做哪些事情呢？'
            );
            drawAnalysis(ctx, 196 + 12 + 24 + 36, resizeRatio, target);

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [resizeRatio, width, title, image, target, fixedCode]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
