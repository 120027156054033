import { useState, useEffect } from "react";
import QRCode from 'qrcode';

export default function useQRCode(url) {
  const [code, setCode] = useState(null);

  useEffect(() => {
    if (!url) {
      return;
    }
    QRCode.toDataURL(url).then(setCode);
  }, [url]);

  return code;
}
