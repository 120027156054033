import React, { useRef, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { getQuizAnswersFromCell } from '@boyuai/jupyter-helper/lib/poster-utils';
import useWxShare from '../../../hooks/wx-share';
import useUserInfo from '../../../hooks/user-info';
import useReadImage from '../../../hooks/read-image';
// import useQRCode from '../../../hooks/qrcode';
import '../../main.css';
import { downloadPng, getMaxLength } from '../../../utils';
import { useRouteMatch } from 'react-router-dom';
import useStudyResultBlock from './study-result-block';
import useTaskBlock from './task-block';

export default function LessonReportExperience(props) {
    const { token: publicToken, reportData, width, config } = props;

    // 素材
    const quizOfPersonalInfo = reportData?.materials?.personalInfo?.content;
    const quizOfTarget = reportData?.materials?.target?.content;

    const resizeRatio = width / 414; // 设计稿宽度是414
    const height = 4000;
    const match = useRouteMatch(
        '/learn-report/:publicToken/:lessonId/download'
    );
    const download = match && match.isExact;

    useEffect(() => {
        document.title = '博小鱼信奥编程';
    }, []);

    useWxShare(
        reportData && {
            title: `我在博小鱼学习了《信奥编程体验课》`,
            desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
            link: null,
        }
    );
    // console.log(reportData);

    // 用户信息
    const userInfo = useUserInfo(
        publicToken,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );
    let name;
    let grade;
    let target;
    if (config.version === 3) {
        console.log('已启用新版渲染逻辑');
        const materialMap = reportData.materialsV2 || {};
        name = getQuizAnswersFromCell(materialMap['name'], 'name')[0];
        grade = getQuizAnswersFromCell(materialMap['grade'], 'grade')[0];
        target = getQuizAnswersFromCell(materialMap['target'], 'target')[0];
    } else {
        name = quizOfPersonalInfo && quizOfPersonalInfo['fill-1'][0];
        grade = quizOfPersonalInfo && quizOfPersonalInfo['fill-2'][0];
        target = quizOfTarget && quizOfTarget['fill-4'][0];
    }

    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);

    // 海报图
    const [poster, setPoster] = useState(null);

    const titleAndBackground = useReadImage(
        config.version > 0
            ? 'https://staticcdn.boyuai.com/materials/2020/12/26/kV8cgC_PSQbAts9YkrZ6H.png!png'
            : 'https://staticcdn.boyuai.com/materials/2020/11/25/KPJrdhnc3zF5fL-YJTmV_.png!png'
    );

    // 任务目标
    const [taskBlock, taskBlockCanvas] = useTaskBlock(
        config,
        width,
        resizeRatio
    );
    // 学习成果
    const [studyResultBlock, studyResultBlockCanvas] = useStudyResultBlock(
        config,
        width,
        resizeRatio,
        target
    );

    // 二维码及引导
    const hint = useReadImage(
        'https://staticcdn.boyuai.com/user-assets/6074/ZgYzGNoKWeFSozvrs1XgbS/Group 981.png'
    );

    useEffect(() => {
        if (poster) {
            // 画完不用再画
            return;
        }
        /*console.log(
            canvasRef.current,
            titleAndBackground,
            userInfo,
            name,
            grade,
            target,
            hint,
            reportData,
            taskBlock,
            studyResultBlock
        );*/
        if (
            canvasRef.current &&
            titleAndBackground &&
            userInfo &&
            name &&
            grade &&
            target &&
            hint &&
            reportData &&
            taskBlock &&
            studyResultBlock
        ) {
            const height =
                236 * resizeRatio +
                32 +
                taskBlock.height +
                32 +
                studyResultBlock.height +
                78 * resizeRatio +
                111 * resizeRatio;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${height}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;

            // 画布背景颜色
            ctx.fillStyle = '#F8D57E';
            ctx.fillRect(0, 0, width, height);

            // 大标题和背景
            ctx.drawImage(
                titleAndBackground,
                0,
                0,
                width,
                (titleAndBackground.height / titleAndBackground.width) * width
            );

            // 姓名和年级
            ctx.fillStyle = '#fff';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'left';
            ctx.font = `bold ${Math.floor(16 * resizeRatio)}px Arial`;
            const displayName =
                name.length > 12 ? `${name.slice(0, 12)}...` : name;
            ctx.fillText(
                `姓名 丨 ${displayName}`,
                (47 + 37) * resizeRatio,
                (199 + 37 / 2) * resizeRatio
            );
            // 如果名字长过一半，不显示班级
            if (
                getMaxLength(ctx, [`姓名 丨 ${displayName}`]) <
                (414 / 2 - 74) * resizeRatio
            ) {
                ctx.textAlign = 'left';
                const displayGrade =
                    grade.length > 3 ? `${grade.slice(0, 3)}...` : grade;
                ctx.fillText(
                    `年级 丨 ${displayGrade}`,
                    (414 - 47 - 37 - 101) * resizeRatio,
                    (199 + 37 / 2) * resizeRatio
                );
            }

            // 任务目标
            const taskBlockY = 252 * resizeRatio;
            ctx.drawImage(
                taskBlock,
                0,
                taskBlockY,
                width,
                (taskBlock.height / taskBlock.width) * width
            );

            // 学习成果
            const studyResultBlockY = taskBlockY + taskBlock.height + 31;
            ctx.drawImage(
                studyResultBlock,
                0,
                studyResultBlockY,
                width,
                (studyResultBlock.height / studyResultBlock.width) * width
            );

            const startOnFridayY =
                studyResultBlockY + studyResultBlock.height + 31;
            // 不见不散
            ctx.fillStyle = '#3A59C6';
            ctx.textBaseline = 'top';
            ctx.textAlign = 'center';
            ctx.font = `normal ${Math.floor(22 * resizeRatio)}px Arial`;
            ctx.fillText(
                `${config.startDate}正式开课，`,
                (414 / 2) * resizeRatio,
                startOnFridayY
            );
            ctx.fillText(
                `老师与你不见不散哦！`,
                (414 / 2) * resizeRatio,
                startOnFridayY + 44 + 16
            );

            // 二维码和引导
            const hintY = startOnFridayY + (36 + 12 + 12) * 2;
            ctx.drawImage(
                hint,
                0,
                hintY,
                width,
                (hint.height / hint.width) * width
            );

            // 下载
            if (download) {
                downloadPng(
                    canvasRef.current.toDataURL(),
                    `${reportData.courseTitle}-${
                        userInfo.name ? userInfo.name : '伯禹学员'
                    }`
                );
            }
            // 生成image
            setPoster(canvasRef.current.toDataURL());
        }
    }, [
        poster,
        width,
        height,
        resizeRatio,
        userInfo,
        name,
        grade,
        target,
        reportData,
        titleAndBackground,
        hint,
        download,
        taskBlock,
        studyResultBlock,
        config,
    ]);

    if (!poster) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                {studyResultBlockCanvas}
                {taskBlockCanvas}
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result" style={props.pageStyle}>
            <div className="main-image">
                <img src={poster} alt="" />
            </div>
            {props.children}
        </div>
    );
}
