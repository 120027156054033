import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import useReadImage from '../../../hooks/read-image';
import useUserInfo from '../../../hooks/user-info';
import { downloadPng } from '../../../utils';
import { useDescriptionBlock, useKnowledgeBlock, useBoard, useStamp } from '..'

// 抓虫小能手 - 信奥编程体验课测验报告
export default withRouter(function ExamReport(props) {
    const width = 750;
    const [examData, setExamData] = useState(null);
    const { token, lessonId } = props.match.params;
    useEffect(() => {
        remote.$get(`/learn/admin/exam-result/${lessonId}/${token}`).then(data => {
            setExamData(data);
        })
    }, [lessonId, token])
    const userInfo = useUserInfo(token);

    let theoreticalLevel = 3;
    if (examData?.quizResult) {
        const [right, total] = examData.quizResult;
        if (right / total >= 0.5) {
            theoreticalLevel = 4;
        }
        if (right / total >= 0.7) {
            theoreticalLevel = 5;
        }
    }

    let practiceLevel = 3;
    if (examData?.codeResult) {
        const [right, total] = examData.codeResult;
        if (right / total >= 0.5) {
            practiceLevel = 4;
        }
        if (right / total >= 0.7) {
            practiceLevel = 5;
        }
    }

    let tags;
    let notMasteredTags;
    if (examData?.resultCountByTag) {
        const _tags = []
        const _notMasteredTags = []
        examData.resultCountByTag.forEach(t => {
            if (t.result[0] / t.result[1] >= 0.7) {
                _tags.push(t.tag);
                return;
            }
            _notMasteredTags.push(t.tag);
        })
        tags = _tags;
        notMasteredTags = _notMasteredTags;
    }

    const resizeRatio = width / 414; // 设计稿宽度是414
    const matchOld = useRouteMatch(
        '/learn-exam-report/:publicToken/:lessonId/download'
    );
    const match = useRouteMatch(
        '/learn-report/:publicToken/:lessonId/download'
    );
    const download = (match && match.isExact) || (matchOld && matchOld.isExact);
    useEffect(() => {
        document.title = '博小鱼信奥编程';
    }, []);

    const canvasRef = useRef(null);
    const context = useRef(null);
    const [poster, setPoster] = useState(null);
    const headerImg = useReadImage('https://staticcdn.boyuai.com/materials/2020/12/18/QdwZrArQ9QDTXGbva-bID.png')
    const footerImg = useReadImage('https://staticcdn.boyuai.com/user-assets/6074/oRT3SavzqYnQLMPALpbwav/Group 983.png!png');


    const day = dayjs(examData?.debug?.examFinishedMoment);
    const [stampImg, stampCanvas] = useStamp(resizeRatio, day.get('year'), day.get('month') + 1, day.get('date'));
    const [knowledgeImg, knowledgeCanvas] = useKnowledgeBlock(resizeRatio, '以下知识点掌握很棒：', tags)
    const [notMasteredKnowledgeImg, notMasteredKnowledgeCanvas] = useKnowledgeBlock(resizeRatio, '这些内容还需巩固哦：', notMasteredTags)
    const dividerImg = useReadImage('https://staticcdn.boyuai.com/materials/2020/12/18/HlX_B5jE4mvMX0GX1wAx5.png');
    const [descriptionImg, descriptionCanvas] = useDescriptionBlock({
        resizeRatio,
        userInfo,
        theoreticalLevel,
        practiceLevel,
    })
    const descMarginTop = 24 * resizeRatio;
    const dividerMarginBottom = 16 * resizeRatio;
    const dividerMarginTop = 24 * resizeRatio;
    const dividerWidth = 343 * resizeRatio;
    const knowledgeHeight = knowledgeImg?.height || 0;
    const knowledgeMarginBottom = knowledgeHeight ? 24 * resizeRatio : 0;
    const notMasteredKnowledgeHeight = notMasteredKnowledgeImg?.height || 0;
    const notMasteredKnowledgeMarginBottom = notMasteredKnowledgeHeight ? 24 * resizeRatio : 0;

    const [boardSize, setBoardSize] = useState(null);
    useEffect(() => {
        if (!examData || !knowledgeImg || !notMasteredKnowledgeImg || !userInfo || !stampImg || !descriptionImg || !dividerImg) {
            return;
        }

        const height = (knowledgeHeight + knowledgeMarginBottom)
            + (notMasteredKnowledgeHeight + notMasteredKnowledgeMarginBottom)
            + (descriptionImg.height + descMarginTop)
            + dividerMarginTop + dividerImg.height + dividerMarginBottom;
        setBoardSize({
            width: dividerWidth + 2 * 16 * resizeRatio,
            height,
        })
    }, [descMarginTop, descriptionImg, dividerImg, dividerMarginBottom, dividerMarginTop, dividerWidth, examData, knowledgeHeight, knowledgeImg, knowledgeMarginBottom, notMasteredKnowledgeHeight, notMasteredKnowledgeImg, notMasteredKnowledgeMarginBottom, resizeRatio, stampImg, userInfo])

    const [boardImg, boardCanvas] = useBoard(boardSize?.width, boardSize?.height, resizeRatio)

    useEffect(() => {
        // 元素准备好之前先不渲染
        if (!canvasRef.current || !userInfo || !examData || !dividerImg || !descriptionImg || !headerImg || !footerImg || !boardImg) {
            return;
        }

        const headerHeight = 200 * resizeRatio;
        const footerHeight = 73 * resizeRatio;
        const footerMargin = 12 * resizeRatio;
        const height = headerHeight
            + boardImg.height
            + footerHeight
            + footerMargin * 2
            ;
        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        context.current = canvasRef.current.getContext('2d');
        const ctx = context.current;

        // 画布背景颜色
        ctx.fillStyle = '#F8D57E';
        ctx.fillRect(0, 0, width, height);

        // 大标题
        ctx.drawImage(
            headerImg,
            0,
            0,
            width,
            (headerImg.height / headerImg.width) * width
        );
        let currentHeight = headerHeight;

        // 圆角矩形
        const boardMarginLeft = (width - boardImg.width) / 2
        ctx.drawImage(
            boardImg,
            boardMarginLeft,
            currentHeight,
            boardImg.width,
            boardImg.height
        )

        // 绘制上半部分描述
        const descMarginLeft = (width - descriptionImg.width) / 2
        ctx.drawImage(
            descriptionImg,
            descMarginLeft,
            currentHeight + descMarginTop,
            descriptionImg.width,
            descriptionImg.height
        )
        currentHeight += descriptionImg.height + descMarginTop;
        // 分割线
        const dividerMarginLeft = (width - dividerWidth) / 2
        ctx.drawImage(
            dividerImg,
            dividerMarginLeft,
            currentHeight + dividerMarginTop,
            343 * resizeRatio,
            (dividerImg.height / dividerImg.width) * 343 * resizeRatio
        )
        currentHeight += dividerImg.height + dividerMarginTop + dividerMarginBottom;

        // 绘制下半部分描述
        if (knowledgeImg) {
            ctx.drawImage(
                knowledgeImg,
                dividerMarginLeft + 24 * resizeRatio,
                currentHeight,
                knowledgeImg.width,
                knowledgeImg.height
            )
            currentHeight += knowledgeImg.height + knowledgeMarginBottom;
        }
        if (notMasteredKnowledgeImg) {
            ctx.drawImage(
                notMasteredKnowledgeImg,
                dividerMarginLeft + 24 * resizeRatio,
                currentHeight,
                notMasteredKnowledgeImg.width,
                notMasteredKnowledgeImg.height
            )
        }

        ctx.drawImage(
            stampImg,
            width - stampImg.width,
            headerHeight + boardImg.height + footerMargin - stampImg.height + 8,
            stampImg.width,
            stampImg.height
        )

        ctx.drawImage(
            footerImg,
            width - footerMargin - footerImg.width,
            headerHeight + boardImg.height + footerMargin,
            294 * resizeRatio,
            (footerImg.height / footerImg.width) * 294 * resizeRatio
        )
        if (download) {
            downloadPng(
                canvasRef.current.toDataURL(),
                `测验报告-${
                    userInfo.name
                }`
            );
        }
        setPoster(canvasRef.current.toDataURL());
    }, [boardImg, descMarginTop, descriptionImg, dividerImg, dividerMarginBottom, dividerMarginTop, dividerWidth, download, examData, footerImg, headerImg, knowledgeImg, knowledgeMarginBottom, notMasteredKnowledgeImg, resizeRatio, stampImg, userInfo])

    if (!examData || !poster) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                {knowledgeCanvas}
                {notMasteredKnowledgeCanvas}
                {boardCanvas}
                {descriptionCanvas}
                {stampCanvas}
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result">
            <div className="main-image">
                <img src={poster} alt="" />
            </div>
            {props.children}
        </div>
    );
});
