import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CourseReport from '../components/learn-course-report/course-report';
import './learn-course-report.less';
import useLearnCourseReportData from '../hooks/learn-course-report-data';
import CourseReportYEAction from '../components/learn-course-report/YEAction';

export default withRouter(function LearnCourseReport(props) {
    const { token, courseId } = props.match.params;
    const [height, setHeight] = useState(0);

    const reportData = useLearnCourseReportData(token, courseId);

    const setShareHintHeight = useCallback(() => {
        let height = 0;
        const clientWidth =
            document.body.clientWidth > 450 ? 450 : document.body.clientWidth;
        if (document.body.clientHeight / clientWidth > 1624 / 750) {
            height = (document.body.clientHeight * 750) / clientWidth - 1624;
        }
        setHeight(
            (clientWidth / 750) *
                ((reportData && reportData.posterType === 'YEAction'
                    ? 195
                    : 100) +
                    height)
        );
    }, [reportData]);

    useEffect(() => {
        setShareHintHeight();
        window.addEventListener('resize', setShareHintHeight);
        return () => window.removeEventListener('resize', setShareHintHeight);
    }, [setShareHintHeight]);
    if (!reportData) {
        return null;
    }
    return (
        <div
            style={{
                minHeight: '100vh',
                backgroundColor: '#fff',
            }}
        >
            {reportData.posterType === 'YEAction' ? (
                <CourseReportYEAction
                    token={token}
                    reportData={reportData}
                    width={750}
                >
                    <div
                        className="learn-course-report-share-hint-q-bit-ai"
                        // style={{ height: `${height}px` }}
                    >
                        <img
                            src={
                                'https://staticcdn.boyuai.com/materials/2020/10/29/PlIjCuTbTqClr1fwNVt0B.png!png'
                            }
                            alt=""
                            width={'100%'}
                        />
                    </div>
                </CourseReportYEAction>
            ) : (
                <CourseReport
                    token={token}
                    reportData={reportData}
                    width={750}
                    height={1624}
                >
                    <div
                        className="learn-course-report-share-hint-q-bit-ai"
                        style={{ height: `${height}px` }}
                    >
                        <img
                            src={
                                'https://staticcdn.boyuai.com/materials/2020/07/04/OHu-VVYWlta-xxAixGbBg.png!png'
                            }
                            alt=""
                            width={'100%'}
                        />
                    </div>
                </CourseReport>
            )}
        </div>
    );
});
