import { useState, useEffect } from "react";
import { loadImage } from '../utils';

export default function useReadImage(url) {
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (!url) {
      return;
    }
    loadImage(url).then(setImage);
  }, [url]);
  return image;
}
