import { useState, useEffect, useCallback } from 'react';
import { remote } from '@boyuai/utils';

export default function useLearnCourseReportData(publicToken, courseId) {
    const [reportData, setReportData] = useState(null);
    const generateReportData = useCallback(async () => {
        if (courseId && publicToken) {
            const res = await remote.$get(
                `/learn-events/public/${publicToken}/course/${courseId}`
            );
            if (res && res.reportData) {
                setReportData({
                    ...res.reportData,
                    time: res.time, // 需要用到时间
                });
            }
        }
    }, [courseId, publicToken]);
    useEffect(() => {
        generateReportData();
    }, [generateReportData]);
    return reportData;
}
