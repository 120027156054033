import React, { useLayoutEffect, useState } from 'react'
import { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import html2canvas from 'html2canvas';
import moment from 'moment';
import useUserInfo from '../../../hooks/user-info';
import useWxShare from '../../../hooks/wx-share';
import { downloadPng } from '../../../utils';
import '../../main.css';
import './index.less';
import useReadImage from '../../../hooks/read-image';

export const LessonReportL0Formal3 = (props) => {
  const { token: publicToken, reportData } = props;
  const match = useRouteMatch(
    '/learn-report/:publicToken/:lessonId/download'
  );
  const download = match && match.isExact;
  const resizeRatio = (window.innerWidth > 450 ? 450 : window.innerWidth) / 414; // 设计稿宽度是 414

  const [canvas, setCanvas] = useState(null);
  const [imgReady, setImgReady] = useState(false);

  useEffect(() => {
    document.title = '博小鱼信奥编程';
  }, []);

  useWxShare(
    reportData && {
      title: `我在博小鱼学习了《${reportData.courseTitle}》`,
      desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
      link: null,
    }
  );

  const userInfo = useUserInfo(publicToken);
  let name = userInfo?.realName || userInfo?.name || '博小鱼学员';
  // name = '啊啊啊啊啊啊啊啊啊啊啊啊啊啊'
  // name = '啊啊啊啊啊啊啊啊啊啊'
  let zoomSmall = false;
  let splitIndex;
  // 英文字符占 1 位，中文字符占 2 位，找到缩小点和分割点
  name.split('').reduce((sum, char, currentIndex) => {
    if (/[a-z A-Z]/.test(char)) {
      if (sum >= 18) {
        zoomSmall = true;
      }
      if (sum >= 28) {
        splitIndex = currentIndex;
      }
      return sum + 1;
    }
    if (sum >= 17) {
      zoomSmall = true
    }
    if (sum >= 27) {
      splitIndex = currentIndex;
    }
    return sum + 2;
  }, 0)
  // name = 'aaaaaaaaaa'
  // name = 'aaaaaaaaa'
  if (splitIndex) {
    name = name.slice(0, splitIndex - 3) + '...'
  }
  const nameFontSize = zoomSmall ? Math.pow(0.9, 3) * 28 * resizeRatio : 28 * resizeRatio;
  const displayTime = moment(reportData.time).format(
    'YYYY 年 MM 月 DD 日'
  );
  const originalLines = reportData.accumulatedCodeLines;
  let lines = originalLines > 9999 ? Math.floor(originalLines / 10000) : originalLines;
  if (originalLines / 10000 > 10) {
    lines = 10;
  }
  const lineUnit = originalLines > 9999 ? '万+行' : '行'

  const originalDuration = reportData.accumulatedStudyDuration > 400 ? reportData.accumulatedStudyDuration : 400;
  let duration = originalDuration > 999 ? Math.floor(originalDuration / 1000) : originalDuration;
  let durationUnit = originalDuration > 999 ? '千+分钟' : '分钟'
  if (originalDuration / 10000 > 10) {
    duration = 1;
    durationUnit = '万+分钟'
  }

  // 解决 canvas 读 cache 无 CORS header 的问题
  const posterImg = useReadImage('https://staticcdn.boyuai.com/materials/2021/02/07/GO4zY_scL3uw0zKlX0UBv.png')
  useLayoutEffect(() => {
    if (!posterImg) return;

    const wrapper = document.getElementById('poster-wrapper');
    wrapper.appendChild(posterImg);
    setImgReady(true);
  }, [posterImg])

  // 监听下载
  useLayoutEffect(() => {
    if (!reportData || !userInfo) return;

    if (download && canvas) {
      downloadPng(
        canvas.toDataURL(),
        `${reportData.courseTitle}-${userInfo.name ? userInfo.name : '伯禹学员'
        }`
      );
    }
  }, [canvas, download, reportData, userInfo])

  useLayoutEffect(() => {
    // 等海报底图和用户数据加载完才生成 canvas
    if (!imgReady || !reportData || !userInfo) return;
    const wrapper = document.getElementById('poster-wrapper');
    html2canvas(document.getElementById('poster-wrapper'), {
      useCORS: true,
    }).then(canvas => {
      setCanvas(canvas)
      wrapper.remove()
    })
  }, [imgReady, reportData, userInfo])

  // 等生成 canvas 才结束 loading
  return (
    <div className="page-result L0-formal-3" style={props.pageStyle}>
      {canvas ? (
        <div className="main-image">
          <img alt="poster" src={canvas.toDataURL()} />
        </div>
      ) : (
          <Skeleton active />
        )}

      <div style={{ marginTop: 2000 }} className="main-image" id="poster-wrapper">
        <span
          className="name"
          style={{
            fontSize: nameFontSize,
            lineHeight: `${39 * resizeRatio}px`,
            left: 34 * resizeRatio,
            top: 108 * resizeRatio,
          }}
        >
          {name}同学的
        </span>
        <span
          className="finished-at"
          style={{
            fontSize: 15 * resizeRatio,
            lineHeight: `${21 * resizeRatio}px`,
            left: 36 * resizeRatio,
            top: 231 * resizeRatio,
          }}
        >
          完成时间：{displayTime}
        </span>
        <div
          className="data"
          style={{
            left: 36 * resizeRatio,
            top: 300 * resizeRatio,
          }}
        >
          <span style={{
            fontSize: 36 * resizeRatio,
            lineHeight: `${49 * resizeRatio}px`,
          }}>{reportData.accumulatedLessonNum > 8 ? reportData.accumulatedLessonNum : 8}</span>
          <span style={{
            marginLeft: 2 * resizeRatio,
            fontSize: 16 * resizeRatio,
            lineHeight: `${22 * resizeRatio}px`,
          }}>课时</span>
        </div>
        <div
          className="data"
          style={{
            left: 147 * resizeRatio,
            top: 300 * resizeRatio,
          }}
        >
          <span style={{
            fontSize: 36 * resizeRatio,
            lineHeight: `${49 * resizeRatio}px`,
          }}>{duration}</span>
          <span style={{
            marginLeft: 2 * resizeRatio,
            fontSize: 16 * resizeRatio,
            lineHeight: `${22 * resizeRatio}px`,
          }}>{durationUnit}</span>
        </div>
        <div
          className="data"
          style={{
            left: 282 * resizeRatio,
            top: 300 * resizeRatio,
          }}
        >
          <span style={{
            fontSize: 36 * resizeRatio,
            lineHeight: `${49 * resizeRatio}px`,
          }}>{lines}</span>
          <span style={{
            marginLeft: 2 * resizeRatio,
            fontSize: 16 * resizeRatio,
            lineHeight: `${22 * resizeRatio}px`,
          }}>{lineUnit}</span>
        </div>
      </div>
    </div>
  )
}
