export const loadImage = (url) =>
    new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.crossOrigin = 'anonymous';
        img.src = url.startsWith('data:')
            ? url
            : url + '?t=' + new Date().getTime(); // 缓存的图片可能会跨域(原因不明)
    });

// 自定义百分比，如果分母是0，结果也是0
export function getPercentage(numerator, denominator) {
    if (denominator === 0) {
        return 0;
    } else {
        return parseFloat((numerator / denominator).toFixed(2));
    }
}

export function downloadPng(url, name) {
    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = `${name}.png` || 'image.png';
    a.href = url;
    // 触发a的单击事件
    a.dispatchEvent(event);
}

function drawRoundRectPath(ctx, width, height, radius) {
    ctx.beginPath(0);
    //从右下角顺时针绘制，弧度从0到1/2PI
    ctx.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

    //矩形下边线
    ctx.lineTo(radius, height);

    //左下角圆弧，弧度从1/2PI到PI
    ctx.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

    //矩形左边线
    ctx.lineTo(0, radius);

    //左上角圆弧，弧度从PI到3/2PI
    ctx.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);

    //上边线
    ctx.lineTo(width - radius, 0);

    //右上角圆弧
    ctx.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);

    //右边线
    ctx.lineTo(width, height - radius);
    ctx.closePath();
}

/**该方法用来绘制一个有填充色的圆角矩形
 *@param ctx:canvas的上下文环境
 *@param x:左上角x轴坐标
 *@param y:左上角y轴坐标
 *@param width:矩形的宽度
 *@param height:矩形的高度
 *@param radius:圆的半径
 *@param fillColor:填充颜色
 **/
export function fillRoundRect(
    ctx,
    x,
    y,
    width,
    height,
    radius,
    /*optional*/ fillColor
) {
    //圆的直径必然要小于矩形的宽高
    if (2 * radius > width || 2 * radius > height) {
        return false;
    }

    ctx.save();
    ctx.translate(x, y);
    //绘制圆角矩形的各个边
    drawRoundRectPath(ctx, width, height, radius);
    ctx.fillStyle = fillColor || '#000'; //若是给定了值就用给定的值否则给予默认值
    ctx.fill();
    ctx.restore();
}

// 获取 str数组逐行画到canvas上宽度最大的一行的宽度
export const getMaxLength = (ctx, texts) => {
    let max = 0;
    for (const t of texts) {
        const { width } = ctx.measureText(t);
        max = max > width ? max : width;
    }
    return max;
};

// 自动换行
export function autoWrapString(ctx, str, maxWidth) {
    const pieces = [].concat.apply(
        [],
        str
            .split(/([a-zA-z0-9]+)/g)
            .map((x) =>
                x.trim() !== '' && !x.match(/[a-zA-z0-9]+/g) ? x.split('') : [x]
            )
    );
    let lines = [];
    let newLine = [];
    while (pieces.length) {
        const piece = pieces.shift();
        const { width } = ctx.measureText([...newLine, piece].join(''));
        if (width > maxWidth) {
            if (/\p{P}/u.test(piece) && piece.length === 1) {
                // 如果是单个标点
                newLine.push(piece);
                lines.push(newLine);
                newLine = [];
            } else {
                // 加上新的超宽了
                lines.push(newLine);
                // 换行
                newLine = [piece];
            }
        } else {
            // 没有超宽，则放入
            newLine.push(piece);
        }
    }
    // 没填完的放到最后一行
    lines.push(newLine);
    return lines.map((arr) => arr.join(''));
}

// 超宽打点
export function autoEllipsis(ctx, str, maxWidth) {
    let length = str.length;
    let currentStr = str;
    let width = ctx.measureText(currentStr).width;
    while (width > maxWidth) {
        if (length === str.length) {
            width = ctx.measureText(currentStr).width;
        } else {
            currentStr = `${str.slice(0, length)}...`;
            width = ctx.measureText(currentStr).width;
        }
        length--;
    }
    return currentStr;
}

// aspectFit
export const drawAspectFit = (ctx, image, x1, y1, x2, y2) => {
    let ratio = image.width / image.height; // 按照原图比例
    const maxWidth = x2 - x1;
    const maxHeight = y2 - y1;
    if (maxWidth / maxHeight < ratio) {
        const imageHeight = maxWidth / ratio;
        ctx.drawImage(
            image,
            x1,
            y1 + (maxHeight - imageHeight) / 2,
            maxWidth,
            imageHeight
        );
    } else {
        const imageWidth = ratio * maxHeight;
        ctx.drawImage(
            image,
            x1 + (maxWidth - imageWidth) / 2,
            y1,
            imageWidth,
            maxHeight
        );
    }
};

// 以左上角定位，固定宽度以图片原比例绘图，返回resize后图片高度
export const drawWidthFixed = (ctx, image, width, { x0, y0 }) => {
    let ratio = image.height / image.width; // 按照原图比例
    // ctx.fillRect(x0, y0, width, width * ratio); // debug用
    ctx.drawImage(image, x0, y0, width, width * ratio);
    return width * ratio;
};
