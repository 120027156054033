import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Main from '../components/main';
import useQRCode from '../hooks/qrcode';
import TagCloud from '../components/tag-cloud';
import './qianren-tags.less';

const defaultLessons = ['暂无购买'];

export default withRouter(function QianrenTags(props) {
  const [shareUrl, setShareUrl] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [name, setName] = useState(null);
  const qrcode = useQRCode(shareUrl);
  const [height, setHeight] = useState(0);
  const resolveRef = useRef(null);
  const qrcodeRef = useRef(null);
  const manRef = useRef(null);
  const qrcodeBgRef = useRef(null);

  const setShareHintHeight = useCallback(() => {
    let height = 0;
    const clientWidth = document.body.clientWidth > 450 ? 450 : document.body.clientWidth;
    if (document.body.clientHeight / clientWidth > 1597 / 750) {
      height = document.body.clientHeight * 750 / clientWidth - 1597;
    }
    setHeight(clientWidth / 750 * (142 + height));
  }, []);
  useEffect(() => {
    setShareHintHeight();
    window.addEventListener('resize', setShareHintHeight);
    return () => window.removeEventListener('resize', setShareHintHeight);
  }, [setShareHintHeight])

  const { token } = props.match.params;

  const onUserInfoLoaded = (info) => {
    const site = process.env.REACT_APP_ENV === 'production' ? 'www.boyuai.com/elites' : 'dev.boyuai.com/course';
    setShareUrl(`https://${site}/register/${info.referralCode}`);
    setLessons(info.public.qianrenLessons || defaultLessons);
    setName(info.public.realName || info.name);
  };
  const drawInfo = (ctx, info) => {
  }
  const drawImages = (ctx, images) => {
    const [qrcode, man, qrcodeBg] = images;
    manRef.current = man;
    qrcodeRef.current = qrcode;
    qrcodeBgRef.current = qrcodeBg;
  }
  const onTagCloudReady = useCallback(async (canvas) => {
    if (!resolveRef.current) {
      // 字云比图片先加载完毕，那么需要等一等
      await new Promise((resolve) => {
        const fetch = () => {
          if (resolveRef.current) {
            resolve();
          } else {
            setTimeout(fetch, 100);
          }
        };
        fetch();
      });
    }
    resolveRef.current(canvas);
  }, []);
  const beforeEnding = useCallback((ctx) => {
    return new Promise((resolve) => {
      resolveRef.current = (canvas) => {
        ctx.save();
        ctx.drawImage(
          canvas,
          0, 0, canvas.width, canvas.height,
          150, 680, canvas.width, canvas.height,
        );
        ctx.restore();
        resolve();

        const man = manRef.current;
        ctx.drawImage(
          man,
          0, 0, man.width, man.height,
          0, 1252, man.width, man.height,
        );

        const qrcodeBg = qrcodeBgRef.current;
        ctx.drawImage(
          qrcodeBg,
          0, 0, qrcodeBg.width, qrcodeBg.height,
          0, 1450, qrcodeBg.width, qrcodeBg.height,
        );

        ctx.fillStyle = '#fff';
        ctx.font = '26px Arial';
        ctx.fillText(name || '', 215, 378);

        const qrcode = qrcodeRef.current;
        ctx.drawImage(
          qrcode,
          0, 0, qrcode.width, qrcode.height,
          24, 1510, 100, 100,
        );
        ctx.fillStyle = '#E5663A';
        ctx.font = 'normal 36px Arial';
        ctx.fillText('扫码查看我给你的“一起学”福利哦', 137, 1550);
        ctx.fillStyle = '#000';
        ctx.font = 'normal 24px Arial';
        ctx.fillText('我在伯禹学习平台等你', 137, 1590);
      };
    });
  }, [name]);
  const generateShareQRCodeLink = (info) => info && info.public && `https://www.boyuai.com/elites/register/${info.public.referralCode}`;
  return <div style={{
    minHeight: '100vh',
    backgroundColor: '#fff',
  }}><Main
    token={token}
    template="https://staticcdn.boyuai.com/materials/2020/02/24/tQVquIsK9XFpfqlXsfW-M.png!png"
    width={750}
    height={1624}
    drawInfo={drawInfo}
    drawImages={drawImages}
    generateShareQRCodeLink={generateShareQRCodeLink}
    images={[qrcode, 'https://staticcdn.boyuai.com/materials/2020/02/24/arOqLDviN8Gf_a7K05cp6.png!png', 'https://staticcdn.boyuai.com/materials/2020/02/24/cgmDQnGvAEFwgWVbu69k7.png!png']}
    onUserInfoLoaded={onUserInfoLoaded}
    beforeEnding={beforeEnding}
    backgroundColor='#2FB1F8'
  >
    {qrcode && <div className="share-hint" style={{ height: `${height}px` }}>
      <div className="qrcode">
        <img src={qrcode} alt="二维码" width="50" height="50" />
      </div>
      <div className="main">
        <div>长按上方图片保存分享</div>
        <div>邀请好友赚雨滴</div>
      </div>
    </div>}
  </Main>
  <div style={{ height: '0px', overflow: 'hidden' }}>
    <TagCloud items={lessons} onFinish={onTagCloudReady} color="#ffffff" backgroundColor="#064C8C" width="430px" height="680px" />
  </div>
  </div>;
});
