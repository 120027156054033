import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Playground from './pages/playground';
import './App.css';
import Register from './pages/register';
import QianrenTags from './pages/qianren-tags';
import QianrenCert from './pages/qianren-cert';
import LearnReport from './pages/learn-lesson-report';
import LearnExamReport from './pages/learn-exam-report';
import LearnCourseReport from './pages/learn-course-report';

export default class App extends React.Component {
    render() {
        return (
            <div className="app">
                <Router>
                    <Route path="/playground/:token" component={Playground} />
                    <Route path="/register/:token" component={Register} />
                    <Route
                        path="/qianren-tags/:token"
                        component={QianrenTags}
                    />
                    <Route
                        path="/qianren-cert/:token"
                        component={QianrenCert}
                    />
                    <Route
                        path="/learn-report/:token/:lessonId"
                        component={LearnReport}
                    />
                    <Route
                        path="/learn-course-report/:token/:courseId/"
                        component={LearnCourseReport}
                    />
                    <Route
                        // 这个路由过阵子可以废弃了，exam 和 lesson 的海报已合并
                        path="/learn-exam-report/:token/:lessonId"
                        component={LearnExamReport}
                    />
                </Router>
            </div>
        );
    }
}
