import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { fillRoundRect } from '../../../utils';

const monospaceFont = 'Consolas'; // 分析直接用这个

const drawTitle = (ctx, height, resizeRatio, title, ignoreDot) => {
    // 标题左侧的点
    if (!ignoreDot) {
        ctx.beginPath(); //开始绘制
        ctx.arc(
            (18 + 30 + 4) * resizeRatio,
            (height + 12) * resizeRatio,
            4 * resizeRatio,
            0,
            2 * Math.PI
        ); //arc 的意思是“弧”
        ctx.fillStyle = '#3A59C6'; //设置填充颜色
        ctx.fill(); //开始填充
        ctx.strokeStyle = '#3A59C6';
        ctx.stroke();
    }
    // 标题
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#3A59C6';
    ctx.font = `normal ${16 * resizeRatio}px Arial`;
    ctx.fillText(title, (18 + 53) * resizeRatio, (height + 12) * resizeRatio);
};

export default function useTaskBlock(width, resizeRatio) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/26/-ZMXjVW0vFUx_SmmzOU8l.png!png' // 任务目标
    );

    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && title) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度
            // 白色块高度
            ctx.font = `bold ${Math.floor(
                12 * resizeRatio
            )}px ${monospaceFont}`;
            const blockHeight = 104; // 圆角矩形高度
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + 27;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                41 * resizeRatio,
                currentHeight,
                104 * resizeRatio,
                27 * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += 27;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#F08200');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                12,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                6,
                '#fff'
            );

            currentHeight += 12;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '学习用代码解决生活中常见的计算问题'
            );
            currentHeight += 28;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '理解小数在计算机中的表示和用法'
            );
            currentHeight += 28;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '输入和输出的另一种方式（scanf、printf）'
            );

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [resizeRatio, width, title, image]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
