import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { drawAspectFit, fillRoundRect } from '../../../utils';
import { handleCode } from '../../../utils/poster';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const CODE_FONT_STYLE = isMobile ? 'Monospace' : 'Consolas';

const TITLE_X = 41; //
const TITLE_WIDTH = 104;
const TITLE_HEIGHT = 27; // 大标题的高度
const TITLE_FONT_SIZE = 16; // 带点的小标题的
const TITLE_LINE_HEIGHT = 16 + 8; // 带点的小标题的,1.5倍行高
const CODE_FONT_SIZE = 12;
const CODE_LINE_HEIGHT = CODE_FONT_SIZE + 2;
const BLOCK_IMAGE_WIDTH = 312;
const FIXED_CODE_HEIGHT = 273;
const FIXED_IMAGE_1_HEIGHT = 199;
const FIXED_IMAGE_2_HEIGHT = 164;

export default function useStudyResultBlock(width, resizeRatio, code) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/25/xzzT5pPlryY8PBnVxn2zn.png!png' // 任务目标
    );

    const fixedCode = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/27/Mhn2dhDyhFlb8IlsIDcD6.png!png'
    );
    const fixedImage1 = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/26/EV3dcSx3iXcrpk7m6ygbs.png!png'
    );
    const fixedImage2 = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/11/26/u4GXVzdWcAYR8FZ0EBVJ5.png!png'
    );

    useEffect(() => {
        if (image) {
            return;
        }
        if (
            canvasRef.current &&
            title &&
            code &&
            fixedCode &&
            fixedImage1 &&
            fixedImage2
        ) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度（按照设计稿尺度）
            // 块的高度=固定元素高度和 + 自适应元素高度和
            const blockHeight =
                2 +
                12 +
                TITLE_LINE_HEIGHT * 2 +
                6 +
                FIXED_CODE_HEIGHT +
                24 +
                TITLE_LINE_HEIGHT * 2 +
                6 +
                FIXED_IMAGE_1_HEIGHT +
                24 +
                TITLE_LINE_HEIGHT * 2 +
                6 +
                FIXED_IMAGE_2_HEIGHT +
                24 +
                TITLE_LINE_HEIGHT +
                6 +
                measureCodeRectHeight(null, code) +
                24 +
                2;
            // canvas高度 = 块+标题
            const canvasHeight = blockHeight + TITLE_HEIGHT;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                TITLE_X * resizeRatio,
                currentHeight,
                TITLE_WIDTH * resizeRatio,
                TITLE_HEIGHT * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += TITLE_HEIGHT;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#F08200');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 分析1
            currentHeight += 14;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '初识cout、cin、条件语句，用代码实现'
            );
            currentHeight += TITLE_LINE_HEIGHT;
            drawTitle(ctx, currentHeight, resizeRatio, '猜数字小游戏', true);
            currentHeight += TITLE_LINE_HEIGHT + 6;
            drawAspectFit(
                ctx,
                fixedCode,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + BLOCK_IMAGE_WIDTH) * resizeRatio,
                (currentHeight + FIXED_CODE_HEIGHT) * resizeRatio
            );
            currentHeight += FIXED_CODE_HEIGHT + 24;

            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '代码编写出的程序是“软件”，是计算机'
            );
            currentHeight += 24;
            drawTitle(ctx, currentHeight, resizeRatio, '的“思想”', true);
            currentHeight += 24 + 8;
            drawAspectFit(
                ctx,
                fixedImage1,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + BLOCK_IMAGE_WIDTH) * resizeRatio,
                (currentHeight + FIXED_IMAGE_1_HEIGHT) * resizeRatio
            );
            currentHeight += FIXED_IMAGE_1_HEIGHT + 24;

            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '拆开计算机看到的设备是“硬件”，是计'
            );
            currentHeight += 24;
            drawTitle(ctx, currentHeight, resizeRatio, '算机的“身体”', true);
            currentHeight += 24 + 8;
            drawAspectFit(
                ctx,
                fixedImage2,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + BLOCK_IMAGE_WIDTH) * resizeRatio,
                (currentHeight + FIXED_IMAGE_2_HEIGHT) * resizeRatio
            );
            currentHeight += FIXED_IMAGE_2_HEIGHT + 24;

            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '彩蛋：动手实现网站跳转小工具'
            );
            currentHeight += 24 + 8;

            drawCode(ctx, currentHeight, resizeRatio, code, CODE_FONT_SIZE);

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [
        resizeRatio,
        width,
        title,
        image,
        code,
        fixedCode,
        fixedImage1,
        fixedImage2,
    ]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
const drawTitle = (ctx, height, resizeRatio, title, ignoreDot) => {
    // 标题左侧的点
    if (!ignoreDot) {
        ctx.beginPath(); //开始绘制
        ctx.arc(
            (18 + 30 + 4) * resizeRatio,
            (height + 12) * resizeRatio,
            4 * resizeRatio,
            0,
            2 * Math.PI
        ); //arc 的意思是“弧”
        ctx.fillStyle = '#3A59C6'; //设置填充颜色
        ctx.fill(); //开始填充
        ctx.strokeStyle = '#3A59C6';
        ctx.stroke();
    }
    // 标题
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#3A59C6';
    ctx.font = `normal ${TITLE_FONT_SIZE * resizeRatio}px Arial`;
    ctx.fillText(title, (18 + 53) * resizeRatio, (height + 12) * resizeRatio);
};

const measureCodeRectHeight = (codesToDisplay, code) => {
    if (!codesToDisplay) {
        codesToDisplay = handleCode(code, { maxLines: 10, language: 'c++' });
    }
    return 14 + codesToDisplay.length * CODE_LINE_HEIGHT + 14;
};
const drawCode = (ctx, height, resizeRatio, code, fontSize) => {
    const rectX = (18 + 30) * resizeRatio;
    const rectY = height * resizeRatio;
    const codeX = (18 + 30 + 22) * resizeRatio;
    const codeY = (height + 17) * resizeRatio;
    const codeWidth = 274 * resizeRatio;

    const codesToDisplay = handleCode(code, { maxLines: 10, language: 'c++' });
    const rectHeight = measureCodeRectHeight(codesToDisplay);
    // 灰色框
    ctx.globalAlpha = 0.3;
    fillRoundRect(
        ctx,
        rectX,
        rectY,
        312 * resizeRatio,
        rectHeight * resizeRatio,
        10 * resizeRatio,
        '#F8D57E'
    );
    ctx.globalAlpha = 1;
    // 绘制代码
    ctx.fillStyle = '#3A59C6';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.font = `bold ${Math.floor(
        fontSize * resizeRatio
    )}px ${CODE_FONT_STYLE}`;
    codesToDisplay.forEach((line, i) => {
        let textWidth = ctx.measureText(line).width;
        while (textWidth > codeWidth) {
            textWidth = ctx.measureText(line).width;
            line = line.slice(0, line.length - 1);
        }
        ctx.fillText(
            `${line}`,
            codeX,
            codeY + i * CODE_LINE_HEIGHT * resizeRatio
        );
    });
};
