import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { autoWrapString, drawAspectFit, fillRoundRect } from '../../../utils';

// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// const monospaceFont = isMobile ? 'Monospace' : 'Consolas';
const monospaceFont = 'Consolas'; // 分析直接用这个

const drawTitle = (ctx, height, resizeRatio, title, ignoreDot) => {
    // 标题左侧的点
    if (!ignoreDot) {
        ctx.beginPath(); //开始绘制
        ctx.arc(
            (18 + 30 + 4) * resizeRatio,
            (height + 12) * resizeRatio,
            4 * resizeRatio,
            0,
            2 * Math.PI
        ); //arc 的意思是“弧”
        ctx.fillStyle = '#2F318A'; //设置填充颜色
        ctx.fill(); //开始填充
        ctx.strokeStyle = '#2F318A';
        ctx.stroke();
    }
    // 标题
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#2F318A';
    ctx.font = `normal ${16 * resizeRatio}px Arial`;
    ctx.fillText(title, (18 + 53) * resizeRatio, (height + 12) * resizeRatio);
};

const handleText = (ctx, text, textYStep, textWidth) => {
    const displayTextList =
        text.length > 250
            ? autoWrapString(ctx, `${text.slice(0, 250)}...`, textWidth)
            : autoWrapString(ctx, text, textWidth);
    return [displayTextList, displayTextList.length * textYStep + 34];
};

const drawAnalysis = (ctx, height, resizeRatio, text) => {
    const rectX = (18 + 30) * resizeRatio;
    const rectY = height * resizeRatio;
    const codeX = (18 + 30 + 22) * resizeRatio;
    const codeY = (height + 17) * resizeRatio;
    const codeWidth = 274 * resizeRatio;
    const textYStep = 16 * resizeRatio;

    ctx.font = `bold ${Math.floor(12 * resizeRatio)}px ${monospaceFont}`;
    const [finalTextList, rectHeight] = handleText(
        ctx,
        text,
        16,
        274 * resizeRatio
    );
    // 灰色框
    ctx.globalAlpha = 0.3;
    fillRoundRect(
        ctx,
        rectX,
        rectY,
        312 * resizeRatio,
        rectHeight * resizeRatio,
        10 * resizeRatio,
        '#2F318A'
    );
    ctx.globalAlpha = 1;
    // 绘制分析
    ctx.fillStyle = '#2F318A';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    finalTextList.forEach((line, i) => {
        let textWidth = ctx.measureText(line).width;
        while (textWidth > codeWidth) {
            textWidth = ctx.measureText(line).width;
            line = line.slice(0, line.length - 1);
        }
        ctx.fillText(`${line}`, codeX, codeY + i * textYStep);
    });
    return rectHeight;
};

export default function useAnalysisBlock(
    width,
    resizeRatio,
    displayImages,
    textList
) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/10/29/4GkUkJH0pMVDxFoMiUrAf.png!png'
    );

    const picture0 = useReadImage(displayImages && displayImages[0]);
    const picture1 = useReadImage(displayImages && displayImages[1]);
    const picture2 = useReadImage(displayImages && displayImages[2]);

    useEffect(() => {
        if (image) {
            return;
        }
        if (
            canvasRef.current &&
            title &&
            picture0 &&
            picture1 &&
            picture2 &&
            textList &&
            textList.length === 3
        ) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度
            // 白色块高度
            ctx.font = `bold ${Math.floor(
                12 * resizeRatio
            )}px ${monospaceFont}`;
            const blockHeight =
                textList.reduce((prev, item) => {
                    const [, blockHeight] = handleText(
                        ctx,
                        item,
                        16,
                        274 * resizeRatio
                    );
                    return prev + blockHeight + 29 + 32 + 176 + 20; // 29和32是标题上下的空隙 176和20是图片的高度和下方空袭
                }, 0) +
                16 +
                24; // 16是底部留白 24是第三个标题多了一行
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + 60;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                18 * resizeRatio,
                currentHeight,
                153 * resizeRatio,
                60 * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += 42;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#E44B71');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 分析1
            currentHeight += 32;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '好评电影的上映年份分析'
            );
            currentHeight += 29;
            drawAspectFit(
                ctx,
                picture0,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + 312) * resizeRatio,
                (currentHeight + 176) * resizeRatio
            );
            currentHeight += 176 + 20;
            currentHeight += drawAnalysis(
                ctx,
                currentHeight,
                resizeRatio,
                textList[0]
            );

            // 分析2
            currentHeight += 24;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '好评电影较多的导演擅长的电影类别分析'
            );
            currentHeight += 29;
            drawAspectFit(
                ctx,
                picture1,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + 312) * resizeRatio,
                (currentHeight + 176) * resizeRatio
            );
            currentHeight += 176 + 20;
            currentHeight += drawAnalysis(
                ctx,
                currentHeight,
                resizeRatio,
                textList[1]
            );

            // 分析3
            currentHeight += 24;
            drawTitle(
                ctx,
                currentHeight,
                resizeRatio,
                '获奥斯卡最佳影片奖的电影和豆瓣好评电'
            );
            currentHeight += 24;
            drawTitle(ctx, currentHeight, resizeRatio, '影的关系分析', true);
            currentHeight += 29; // 标题下方的空间
            drawAspectFit(
                ctx,
                picture2,
                48 * resizeRatio,
                currentHeight * resizeRatio,
                (48 + 312) * resizeRatio,
                (currentHeight + 176) * resizeRatio
            );
            currentHeight += 176 + 20;
            currentHeight += drawAnalysis(
                ctx,
                currentHeight,
                resizeRatio,
                textList[2]
            );

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [
        resizeRatio,
        width,
        title,
        textList,
        picture0,
        picture1,
        picture2,
        image,
    ]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
