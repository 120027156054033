import React, { useRef, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import useWxShare from '../../hooks/wx-share';
import useUserInfo from '../../hooks/user-info';
import useReadImage from '../../hooks/read-image';
import PropTypes from 'prop-types';
import useQRCode from '../../hooks/qrcode';
import { downloadPng, getPercentage } from '../../utils';
import '../main.css';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

const lessonTitleColors = ['#0884FE', '#3F9069', '#C3A826'];

const dataColors = ['#0884FE', '#72CBA0', '#F3D960'];

const templates = [
    'https://staticcdn.boyuai.com/materials/2020/09/05/53beM8LmubvYE7B_RI2QY.png!png', // computer blue
    'https://staticcdn.boyuai.com/materials/2020/09/05/DVI0QmLVuFargCANq1DZ5.png!png', // cup
    'https://staticcdn.boyuai.com/materials/2020/09/05/2Oc4b9AXMEvqHgJkMWBj9.png!png', // robot
];

const templates_q_bit = [
    'https://staticcdn.boyuai.com/materials/2020/09/05/ose8OW5P4FHzGLUsgpGWz.png!png', // computer blue
    'https://staticcdn.boyuai.com/materials/2020/09/05/tl2hnhB11mX9xZoev6Wt7.png!png', // cup
    'https://staticcdn.boyuai.com/materials/2020/09/05/JQTNHLqiKjhG8Nbnd_6xU.png!png', // robot
];

const templates_YEAction = [
    'https://staticcdn.boyuai.com/materials/2020/10/28/7dY2k0GLH9wSR9Ca9tEiX.png!png', // computer blue
    'https://staticcdn.boyuai.com/materials/2020/10/28/wf2hqp-4dovVlCshs1-iy.png!png', // cup
    'https://staticcdn.boyuai.com/materials/2020/10/28/F-TYKow3eu_aDoSO6k_ez.png!png', // robot
];

const templates_boxiaoyu_cpp = [
    'https://staticcdn.boyuai.com/materials/2021/01/28/8GD0nTLfdqreEl42XHjA7.png!png', // computer blue
    'https://staticcdn.boyuai.com/materials/2021/01/28/9IUlcwQAuNfSBiS7lfFAb.png!png', // cup
    'https://staticcdn.boyuai.com/materials/2021/01/28/DxsDXxzUtm5dGvMCXrQ5K.png!png', // robot
]

const getTemplate = (posterType, typeIndex) => {
    switch (posterType) {
        case 'YEAction':
            return templates_YEAction[typeIndex];
        case 'q-bit-ai': // 量子位数据科学与AI实用课
        case 'q-bit-python': // 量子位python课
            return templates_q_bit[typeIndex];
        case 'boxiaoyu-cpp': // 博小鱼C++课
            return templates_boxiaoyu_cpp[typeIndex];
        case '90min': // 90分钟编程挑战
        default:
            return templates[typeIndex];
    }
};

const titles = [
    '人生苦短，我用Python',
    'Keep Calm and Code Python',
    'Simple is better than complex.  —— The Zen of Python',
    'Readability counts.  —— The Zen of Python',
    'Now is better than never.  —— The Zen of Python',
    '优美胜于丑陋  ——Python之禅',
    '简洁胜于复杂  ——Python之禅',
];

const getMaxLength = (ctx, texts) => {
    let max = 0;
    for (const t of texts) {
        const { width } = ctx.measureText(t);
        max = max > width ? max : width;
    }
    return max;
};
const drawTitle = (ctx, title, width, height) => {
    const extraY = 0.03 * height;
    ctx.fillStyle = '#fff';
    if (title.indexOf('，') !== -1) {
        // 中文逗号分隔的
        ctx.textAlign = 'center';
        const texts = title.split('，');
        let fontSize = Math.floor(height * 0.036);
        ctx.font = `normal ${fontSize}px Arial`;
        let lineHeight = fontSize + 20;
        ctx.fillText(`${texts[0]}`, width / 2, height / 10 + extraY);
        ctx.fillText(
            `${texts[1]}`,
            width / 2,
            height / 10 + lineHeight + extraY
        );
    } else if (title.indexOf('——') !== -1) {
        // 带破折号的
        ctx.textAlign = 'right';
        const texts = [
            title.slice(0, title.indexOf('——')),
            title.slice(title.indexOf('——')),
        ];
        let fontSize = Math.floor(height / 30);
        let fontSize2 = Math.floor(fontSize * 0.7);
        ctx.font = `normal ${fontSize2}px Arial`;
        // 第二行
        while (getMaxLength(ctx, [texts[1]]) > 0.8 * width) {
            fontSize2 -= 1;
            fontSize = Math.floor(fontSize * 1.4);
            ctx.font = `normal ${fontSize2}px Arial`;
        }
        // 第一行
        ctx.font = `normal ${fontSize}px Arial`;
        while (getMaxLength(ctx, [texts[0]]) > 0.8 * width) {
            fontSize -= 1;
            fontSize2 = Math.floor(fontSize * 0.8);
            ctx.font = `normal ${fontSize}px Arial`;
        }
        while (getMaxLength(ctx, [texts[0]]) < 0.6 * width) {
            fontSize += 1;
            ctx.font = `normal ${fontSize}px Arial`;
        }
        let lineHeight = fontSize + 16;
        // ctx.font = `normal ${fontSize}px Arial`; // 前面先判断第二行就是为了省这个赋值
        ctx.textAlign = 'left';
        ctx.fillText(`${texts[0]}`, width * 0.1, height / 10 + extraY);
        ctx.font = `normal ${fontSize2}px Arial`;
        ctx.textAlign = 'right';
        ctx.fillText(
            `${texts[1]}`,
            width * 0.9,
            height / 10 + lineHeight + extraY
        );
    } else {
        ctx.textAlign = 'center';
        let fontSize = Math.floor(height / 30);
        ctx.font = `normal ${fontSize}px Arial`;
        while (getMaxLength(ctx, [title]) > 0.8 * width) {
            fontSize -= 1;
            ctx.font = `normal ${fontSize}px Arial`;
        }
        // 单行的稍微向下移动一点
        ctx.fillText(
            `${title}`,
            width / 2,
            height / 10 + fontSize / 2 + extraY
        );
    }
};
const drawLesson = (ctx, lessonTitle, courseTitle, width, height, type = 0) => {
    const titleX = width * 0.22;
    const maxWidth = 0.59 * width;
    const courseTitleY = height * 0.265;
    const lessonTitleY = height * 0.29;
    let courseTitleFontSize = Math.floor(height * 0.025);
    let lessonTitleFontSize = Math.floor(height / 62);
    // 课程标题
    ctx.textAlign = 'left';
    ctx.fillStyle = '#000';
    ctx.font = `bold ${courseTitleFontSize}px Arial`;
    while (ctx.measureText(courseTitle).width > maxWidth) {
        courseTitleFontSize -= 1;
        ctx.font = `bold ${courseTitleFontSize}px Arial`;
    }
    ctx.fillText(`${courseTitle}`, titleX, courseTitleY);
    // 单元标题
    ctx.textAlign = 'left';
    ctx.fillStyle = lessonTitleColors[type];
    ctx.font = `normal ${lessonTitleFontSize}px Arial`;
    ctx.fillText(`${lessonTitle}`, titleX, lessonTitleY);
};
const drawReport = (ctx, reportData, width, height, type = 0) => {
    const reportTitleY = height * 0.39;
    const reportDataY = height * 0.45;
    const reportTitleFontSize = Math.floor(height * 0.016);
    const reportDataFontSizeLeft = Math.floor(height * 0.032); // 数字
    const reportDataFontSizeRight = Math.floor(height * 0.016); // 单位
    const reportX2Cols = [0.3 * width, 0.7 * width]; // 两列
    const reportX3Cols = [0.25 * width, 0.5 * width, 0.75 * width]; // 三列

    const {
        quizAccuracy,
        codeLines,
        accumulatedCodeLines,
        selfComplete,
        studyDuration,
    } = reportData;
    //
    // const studyDuration = 10000;
    // const accumulatedCodeLines = 1000;
    // const selfComplete = [8, 9];
    // const quizAccuracy = [7, 9];

    // 显示项目
    let displayItems = [];
    // 学习时长项，必有
    const studyDurationItem = {
        title: '学习时长',
        data: studyDuration > 9999 ? '1万+' : studyDuration,
        unit: '分钟',
    };
    // 代码行数项
    const translateCodeLines = (value) =>
        Math.floor(value / 1000)
            ? `${
                  Math.floor(value / 1000) > 9 ? 9 : Math.floor(value / 1000)
              }千+`
            : value;
    const codeLinesItem = {
        title: accumulatedCodeLines ? '累计编写代码' : '编写代码',
        data: accumulatedCodeLines
            ? translateCodeLines(accumulatedCodeLines)
            : translateCodeLines(codeLines),
        unit: '行',
    };
    // quiz正确率项
    const quizAccuracyItem =
        getPercentage(quizAccuracy[0], quizAccuracy[1]) >= 0.8
            ? {
                  // 不低于0.8，百分数
                  title: 'quiz正确率',
                  data: getPercentage(quizAccuracy[0], quizAccuracy[1]) * 100,
                  unit: '%',
              }
            : {
                  // 低于0.8，小数
                  title: 'quiz正确率',
                  data: quizAccuracy[0],
                  unit: `/${quizAccuracy[1]}`,
              };
    // 自主完成率项
    const selfCompleteItem =
        getPercentage(selfComplete[0], selfComplete[1]) >= 0.8
            ? {
                  // 不低于0.8，百分数
                  title: '自主完成率',
                  data: getPercentage(selfComplete[0], selfComplete[1]) * 100,
                  unit: '%',
              }
            : {
                  // 低于0.8，小数
                  title: '自主完成率',
                  data: selfComplete[0],
                  unit: `/${selfComplete[1]}`,
              };
    // xx率项
    const rateItem =
        getPercentage(quizAccuracy[0], quizAccuracy[1]) >=
        getPercentage(selfComplete[0], selfComplete[1])
            ? quizAccuracyItem
            : selfCompleteItem;

    // 按照数据特点选择显示项
    if (accumulatedCodeLines === 0) {
        // 没有代码 => 学习时长 + quiz正确率/自主完成率
        displayItems = [studyDurationItem, rateItem];
    } else if (
        getPercentage(selfComplete[0], selfComplete[1]) < 0.6 &&
        getPercentage(quizAccuracy[0], quizAccuracy[1]) < 0.6
    ) {
        // 有代码 && 两个率都不到0.6 => 学习时长 + 编写代码
        displayItems = [studyDurationItem, codeLinesItem];
    } else {
        displayItems = [studyDurationItem, codeLinesItem, rateItem];
    }
    // 选择三列还是两列
    const reportX = displayItems.length === 3 ? reportX3Cols : reportX2Cols;

    // 开始画画
    // 先写标题
    ctx.fillStyle = '#767676';
    ctx.textAlign = 'center';
    displayItems.forEach((item, index) => {
        ctx.font = `normal ${reportTitleFontSize}px Arial`;
        ctx.fillText(item.title, reportX[index], reportTitleY);
    });
    // 再写数据
    ctx.fillStyle = dataColors[type];
    displayItems.forEach((item, index) => {
        // 计算宽度
        ctx.font = `bold ${reportDataFontSizeLeft}px Arial`;
        let dataTextWidth = ctx.measureText(item.data).width;
        ctx.font = `bold ${reportDataFontSizeRight}px Arial`;
        dataTextWidth += ctx.measureText(item.unit).width;
        // 使组合后的文字能居中
        ctx.font = `bold ${reportDataFontSizeLeft}px Arial`;
        ctx.textAlign = 'left';
        ctx.fillText(
            item.data,
            reportX[index] - dataTextWidth / 2,
            reportDataY
        );
        ctx.font = `bold ${reportDataFontSizeRight}px Arial`;
        ctx.textAlign = 'right';
        ctx.fillText(
            item.unit,
            reportX[index] + dataTextWidth / 2,
            reportDataY
        );
    });
    // 还原
    ctx.textAlign = 'left';
};

const drawUserInfo = (
    ctx,
    portrait,
    name,
    qrcode,
    width,
    height,
    typeIndex = 0
) => {
    // portrait
    const portraitWidth = 0.14 * width;
    const portraitX = 0.12 * width;
    const portraitY = 0.513 * height;
    const centerX = portraitX + portraitWidth / 2;
    const centerY = portraitY + portraitWidth / 2;
    // name
    const nameX = width * 0.3;
    const nameY = 0.54 * height;
    const nameFont = `normal ${Math.floor(height * 0.022)}px Arial`;
    const invitationY = 0.565 * height;
    const invitationFont = `normal ${Math.floor(height * 0.015)}px Arial`;
    const nameLength = 0.4 * width;
    // qrcode
    const qrcodeWidth = width * 0.11;
    const qrcodeX = width * 0.875;
    const qrcodeY = height * 0.943;

    // 头像底框
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fillStyle = lessonTitleColors[typeIndex];
    ctx.fill();
    ctx.strokeStyle = lessonTitleColors[typeIndex];
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(
        portrait,
        0,
        0,
        portrait.width,
        portrait.height,
        portraitX,
        portraitY,
        portraitWidth,
        portraitWidth
    );
    ctx.restore();

    // name
    ctx.fillStyle = '#000';
    ctx.fillOpacity = 0.65;
    ctx.font = nameFont;
    let displayName = name; // 最后展示的名字
    while (getMaxLength(ctx, [displayName]) > nameLength) {
        displayName = `${name.slice(0, displayName.length - 2)}`;
    }
    ctx.fillText(
        `${displayName}${displayName.length < name.length ? '..' : ''}`,
        nameX,
        nameY
    );
    ctx.font = invitationFont;
    ctx.fillText(`本单元学习成就`, nameX, invitationY);
    // draw qrcode if not null
    !qrcode || ctx.drawImage(
        qrcode,
        0,
        0,
        qrcode.width,
        qrcode.height,
        qrcodeX,
        qrcodeY,
        qrcodeWidth,
        qrcodeWidth
    );
};

const drawInvitation = (ctx, name, width, height, posterType) => {
    switch (posterType) {
        case 'q-bit-ai':
            // 量子位数据科学与AI实用课
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `在人工智能学习路上，奠基启航`,
                width * 0.03,
                height * 0.987
            );
            return;
        case 'q-bit-python':
            // 量子位python课
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `掌握Python，开启你的智能时代`,
                width * 0.03,
                height * 0.987
            );
            return;
        case 'boxiaoyu-cpp':
            // 博小鱼C++课
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `学编程，跃龙门`,
                width * 0.03,
                height * 0.987
            );
            return;
        case '90min':
            // 90分钟编程挑战
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `从零开始，90分钟玩转Python`,
                width * 0.03,
                height * 0.987
            );
            return;
        default:
            ctx.fillStyle = '#ffffff';
            ctx.font = `normal ${Math.floor(height * 0.019)}px Arial`;
            ctx.fillText(
                `和${name ? name : '我'}一起`,
                width * 0.03,
                height * 0.97
            );
            ctx.font = `normal ${Math.floor(height * 0.013)}px Arial`;
            ctx.fillText(
                `掌握Python与AI，做智能时代的创造者`,
                width * 0.03,
                height * 0.987
            );
            return;
    }
};

const DEFAULT_SHARE_LINK = 'https://appu5whpj5w7815.h5.xiaoeknow.com/'; // 默认的分享链接

function LessonReport(props) {
    const { token, reportData, width, height } = props;
    const match = useRouteMatch('/learn-report/:token/:lessonId/download');
    const download = match && match.isExact;

    useEffect(() => {
        document.title = (reportData.posterType !== 'boxiaoyu-cpp' ? `伯禹人工智能学院` : `博小鱼信奥编程`);
    }, [reportData.posterType]);

    useWxShare({
        title: reportData && `我正在学习《${reportData.courseTitle}》课程`,
        desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
        link: null,
    }); // 其实什么都没传
    const userInfo = useUserInfo(
        token,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );
    const template = useReadImage(
        reportData &&
            getTemplate(reportData.posterType, reportData.typeIndex % 3)
    );
    // 有userInfo但是没有userInfo.portrait说明用户没有上传头像，故用默认头像
    const portrait = useReadImage(userInfo && userInfo.portrait);
    const qrcode = useReadImage(
        useQRCode(
            reportData &&
                (reportData.shareLink
                    ? reportData.shareLink
                    : DEFAULT_SHARE_LINK)
        )
    );
    const canvasRef = useRef(null);
    const context = useRef(null);

    const [image, setImage] = useState(null);

    useEffect(() => {
        /*console.log(
            template,
            userInfo,
            portrait,
            canvasRef.current,
            qrcode,
            reportData
        );*/
        if (
            !template ||
            !userInfo ||
            !portrait ||
            !canvasRef.current ||
            !qrcode ||
            !reportData
        ) {
            return;
        }

        let nickName = !!userInfo.name 
            ? `${userInfo.name}` 
            : (reportData.posterType !== 'boxiaoyu-cpp' ? `伯禹学员` : `博小鱼学员`);

        // 设置画布
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        // 创建ctx
        context.current = canvasRef.current.getContext('2d');
        // 背景
        context.current.drawImage(template, 0, 0, width, height);
        // 水印时间
        context.current.font = `normal 18px Arial`;
        switch (reportData.typeIndex % 3) {
            case 0:
                context.current.fillStyle = '#42A632';
                break;
            case 1:
                context.current.fillStyle = '#F98600';
                break;
            case 2:
                context.current.fillStyle = '#54ADFF';
                break;
            default:
                context.current.fillStyle = '#54ADFF';
                break;
        }
        context.current.textAlign = 'center';
        context.current.fillText(
            `${moment(reportData.time).get('y')}年${
                moment(reportData.time).get('M') + 1
            }月${moment(reportData.time).get('D')}日`,
            width * 0.7,
            height * 0.335
        );
        context.current.fillText(
            moment(reportData.time).get('h') > 12
                ? `${moment(reportData.time).get('h') - 12}:${moment(
                      reportData.time
                  ).format('mm')} P.M.`
                : `${moment(reportData.time).get('h')}:${moment(
                      reportData.time
                  ).format('mm')} A.M.`,
            width * 0.7,
            height * 0.35
        );
        context.current.textAlign = 'left';
        // 随机标题，boxiaoyu模板不画
        reportData.posterType === 'boxiaoyu-cpp' || drawTitle(
            context.current,
            titles[reportData.titleIndex % titles.length],
            width,
            height
        );
        // drawTitle(context.current, titles[5], width, height);
        drawLesson(
            context.current,
            reportData.courseTitle,
            reportData.lessonTitle,
            width,
            height,
            reportData.typeIndex % 3
        );
        // 数据展示
        drawReport(
            context.current,
            reportData,
            width,
            height,
            reportData.typeIndex % 3
        );
        // 个人信息
        drawUserInfo(
            context.current,
            portrait,
            nickName,
            reportData.posterType !== 'boxiaoyu-cpp' ? qrcode : null, // boxiaoyu模板不画二维码
            width,
            height,
            reportData.typeIndex % 3
        );

        drawInvitation(
            context.current,
            userInfo.name,
            width,
            height,
            reportData.posterType
        );

        if (download) {
            downloadPng(
                canvasRef.current.toDataURL(),
                `${reportData.lessonTitle}-${
                    userInfo.name 
                        ? userInfo.name 
                        : (reportData.posterType !== 'boxiaoyu-cpp' ? `伯禹学员` : `博小鱼学员`)
                }`
            );
        }
        setImage(canvasRef.current.toDataURL());
    }, [
        height,
        width,
        template,
        userInfo,
        portrait,
        qrcode,
        reportData,
        download,
    ]);

    if (!image) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result" style={props.pageStyle}>
            <div className="main-image">
                <img src={image} alt="" />
            </div>
            {props.children}
        </div>
    );
}

LessonReport.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    reportData: PropTypes.shape().isRequired,
};

export default LessonReport;
