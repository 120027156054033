import React, { useRef, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import useWxShare from '../../../hooks/wx-share';
import useUserInfo from '../../../hooks/user-info';
import useReadImage from '../../../hooks/read-image';
// import useQRCode from '../../../hooks/qrcode';
import '../../main.css';
import { autoEllipsis, downloadPng, drawWidthFixed } from '../../../utils';
import { useRouteMatch } from 'react-router-dom';
import useStudyResultBlock from './study-result-block';
import useTaskBlock from './task-block';
import useDataBlock from './data-block';
import useTeacherCommentBlock from './teacher-comment-block';
import moment from 'moment';

export default function LessonReportExperience2(props) {
    const { token: publicToken, reportData, width } = props;

    // 素材
    const code = reportData?.materials?.displayCode;

    const resizeRatio = width / 414; // 设计稿宽度是414
    const height = 4000;
    const match = useRouteMatch(
        '/learn-report/:publicToken/:lessonId/download'
    );
    const download = match && match.isExact;

    useEffect(() => {
        document.title = '博小鱼信奥编程';
    }, []);

    useWxShare(
        reportData && {
            title: `我在博小鱼学习了《信奥编程体验课》`,
            desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
            link: null,
        }
    );
    // console.log(reportData);

    // 用户信息
    const userInfo = useUserInfo(
        publicToken,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );
    const name = userInfo?.name || '博小鱼学员';

    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);

    // 海报图
    const [poster, setPoster] = useState(null);

    const titleAndBackground = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/12/03/xg_4q2HZ9F3hSjCpPkf4z.png!png'
    );

    // 单元数据
    const [dataBlock, dataBlockCanvas] = useDataBlock(
        414,
        60,
        resizeRatio,
        reportData
    );
    // 任务目标
    const [taskBlock, taskBlockCanvas] = useTaskBlock(width, resizeRatio);
    // 学习成果
    const [studyResultBlock, studyResultBlockCanvas] = useStudyResultBlock(
        width,
        resizeRatio,
        code
    );
    // 学习成果
    const [
        teacherCommentBlock,
        teacherCommentBlockCanvas,
    ] = useTeacherCommentBlock(
        width,
        resizeRatio,
        userInfo && (userInfo.name || '该')
    );

    // 二维码及引导
    const hint = useReadImage(
        'https://staticcdn.boyuai.com/user-assets/6074/ZgYzGNoKWeFSozvrs1XgbS/Group 981.png'
    );

    useEffect(() => {
        if (poster) {
            // 画完不用再画
            return;
        }
        /*console.log(
            canvasRef.current,
            titleAndBackground,
            userInfo,
            name,
            grade,
            target,
            hint,
            reportData,
            taskBlock,
            studyResultBlock,
            dataBlock
        );*/
        if (
            canvasRef.current &&
            titleAndBackground &&
            userInfo &&
            name &&
            hint &&
            reportData &&
            taskBlock &&
            studyResultBlock &&
            dataBlock &&
            teacherCommentBlock
        ) {
            const height =
                256 * resizeRatio + // 标题
                27 * resizeRatio +
                dataBlock.height + // 单元数据
                37 * resizeRatio +
                taskBlock.height +
                19 * resizeRatio +
                studyResultBlock.height +
                19 * resizeRatio +
                teacherCommentBlock.height +
                21 * resizeRatio +
                111 * resizeRatio;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${height}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;

            // 画布背景颜色
            ctx.fillStyle = '#F8D57E';
            ctx.fillRect(0, 0, width, height);

            // 大标题和背景
            ctx.drawImage(
                titleAndBackground,
                0,
                0,
                width,
                (titleAndBackground.height / titleAndBackground.width) * width
            );

            // 姓名和年级
            ctx.fillStyle = '#fff';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'left';
            ctx.font = `bold ${Math.floor(16 * resizeRatio)}px Arial`;
            const displayName = autoEllipsis(
                ctx,
                name,
                (414 / 2 - 100) * resizeRatio
            );
            ctx.fillText(
                ` ${displayName}`,
                74 * resizeRatio,
                (219 + 37 / 2) * resizeRatio
            );
            ctx.textAlign = 'left';
            const displayTime = moment(reportData.time).format(
                'YYYY年MM月DD日'
            );
            ctx.fillText(
                `${displayTime}`,
                214 * resizeRatio,
                (219 + 37 / 2) * resizeRatio
            );

            // 单元数据
            const dataBlockY = (256 + 27) * resizeRatio;
            const dataBlockResizedHeight = drawWidthFixed(
                ctx,
                dataBlock,
                width,
                { x0: 0, y0: dataBlockY }
            );

            // 课程目标
            const taskBlockY =
                dataBlockY + dataBlockResizedHeight + 37 * resizeRatio;
            ctx.drawImage(
                taskBlock,
                0,
                taskBlockY,
                width,
                (taskBlock.height / taskBlock.width) * width
            );

            // 学习成果
            const studyResultBlockY =
                taskBlockY + taskBlock.height + 19 * resizeRatio;
            ctx.drawImage(
                studyResultBlock,
                0,
                studyResultBlockY,
                width,
                (studyResultBlock.height / studyResultBlock.width) * width
            );

            // 教师评价
            const teacherCommentBlockY =
                studyResultBlockY + studyResultBlock.height + 19 * resizeRatio;
            ctx.drawImage(
                teacherCommentBlock,
                0,
                teacherCommentBlockY,
                width,
                (teacherCommentBlock.height / teacherCommentBlock.width) * width
            );

            // 二维码和引导
            const hintY =
                teacherCommentBlockY +
                teacherCommentBlock.height +
                21 * resizeRatio;
            ctx.drawImage(
                hint,
                0,
                hintY,
                width,
                (hint.height / hint.width) * width
            );

            // 下载
            if (download) {
                downloadPng(
                    canvasRef.current.toDataURL(),
                    `${reportData.courseTitle}-${
                        userInfo.name ? userInfo.name : '伯禹学员'
                    }`
                );
            }
            // 生成image
            setPoster(canvasRef.current.toDataURL());
        }
    }, [
        poster,
        width,
        height,
        resizeRatio,
        userInfo,
        name,
        reportData,
        titleAndBackground,
        hint,
        download,
        taskBlock,
        studyResultBlock,
        dataBlock,
        teacherCommentBlock,
    ]);

    if (!poster) {
        return (
            <div className="page-result">
                <div className="main-canvas">
                    <canvas ref={canvasRef} />
                </div>
                {studyResultBlockCanvas}
                {taskBlockCanvas}
                {dataBlockCanvas}
                {teacherCommentBlockCanvas}
                <Skeleton active />
            </div>
        );
    }

    return (
        <div className="page-result" style={props.pageStyle}>
            <div className="main-image">
                <img src={poster} alt="" />
            </div>
            {props.children}
        </div>
    );
}
