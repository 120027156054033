import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { Skeleton } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { downloadPng } from '../../../utils';
import useUserInfo from '../../../hooks/user-info';
import useWxShare from '../../../hooks/wx-share';
import { calcDisplayItems } from '../../../utils/poster';
import '../../main.css';
import './index.less';

export const POSTER_WRAPPER_STYLE = {
    opacity: 1,
    marginTop: 2000,
    width: 414,
}
export const html2DataURL = async (element, options = {}) => {
    return html2canvas(element, {
        useCORS: true,
        ...options,
    }).then(canvas => {
        return canvas.toDataURL();
    })
}

export default function LessonReportExperience1(props) {
    const { token: publicToken, reportData } = props;
    const match = useRouteMatch(
        '/learn-report/:publicToken/:lessonId/download'
    );
    const download = match && match.isExact;

    useEffect(() => {
        document.title = '博小鱼信奥编程';
    }, []);

    useWxShare(
        reportData && {
            title: `我在博小鱼学习了《信奥编程体验课》`,
            desc: '快来看看我的学习成果吧~\n更多课程尽在：boyuai.com/learn\n',
            link: null,
        }
    );

    const userInfo = useUserInfo(
        publicToken,
        'https://staticcdn.boyuai.com/materials/2020/06/06/7NN4056Nd3UqQU3KgqRZ_.png!png'
    );

    const [arrowReady, setArrowReady] = useState(false);
    const [bgReady, setBgReady] = useState(false);
    const [posterDataUrl, setPosterDataUrl] = useState();
    useLayoutEffect(() => {
        if (!arrowReady || !bgReady || !reportData || !userInfo) return;

        const wrapper = document.getElementById('poster-wrapper');
        html2DataURL(wrapper).then(dataUrl => {
            setPosterDataUrl(dataUrl);
            wrapper.remove();
            window.scrollTo({ top: 0 });
        })
    }, [arrowReady, bgReady, reportData, userInfo])

    const name = userInfo?.name || '博小鱼学员';

    useLayoutEffect(() => {
        if (!download || !posterDataUrl) return;

        downloadPng(
            posterDataUrl,
            `${reportData.courseTitle}-${name}`
        );
    }, [posterDataUrl, download, name, reportData])

    const displayItems = reportData ? calcDisplayItems(reportData) : [];
    const displayTime = moment(reportData.time).format(
        'YYYY年MM月DD日'
    );

    return (
        <div className="page-result lesson-report-experience1-202106">
            {posterDataUrl ? (
                <div className="main-image">
                    <img alt="poster" src={posterDataUrl} />
                </div>
            ) : (
                <Skeleton active />
            )}

            <div style={POSTER_WRAPPER_STYLE} className="main-image" id="poster-wrapper">
                <div className="name-block">
                    <div className="name">{name}</div>
                    <div>{displayTime}</div>
                </div>
                <div className="data-block">
                    {displayItems.map((item, index) => (
                        <div key={index} style={{ width: `${100 / displayItems.length}%` }}>
                            <div className="title">
                                <img crossOrigin="anonymous" onLoad={() => setArrowReady(true)} className="arrow" alt="" src="https://staticcdn.boyuai.com/user-assets/6074/56i7GQePMQb22Pp6VTzsgz/arrow.svg" />
                                {item.title}
                            </div>
                            <div className="data">{item.data}<span className="unit">{item.unit}</span></div>
                        </div>
                    ))}
                </div>
                <img crossOrigin="anonymous" onLoad={() => setBgReady(true)} alt="" src="https://staticcdn.boyuai.com/user-assets/6074/CsNzM7y8W4BCSt2uYBJ66Y/体验课模板.png" />
            </div>
        </div>
    );
}
