import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Main from '../components/main';

export default withRouter(function Playground(props) {
  const { token } = props.match.params;
  const drawPortrait = (ctx, portraitImage, width) => {
    const portraitWidth = 152;
    const portraitX = 158;
    const portraitY = 422;
    const centerX = portraitX + portraitWidth / 2;
    const centerY = portraitY + portraitWidth / 2;

    // 头像底框
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fillStyle = '#9DE7FF';
    ctx.fill();
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 5;
    ctx.stroke();
    ctx.restore();

    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, portraitWidth / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(
      portraitImage,
      0, 0, portraitImage.width, portraitImage.height,
      portraitX, portraitY, portraitWidth, portraitWidth,
    );
    ctx.restore();
  }
  const drawInfo = (ctx, info, width) => {
    ctx.fillStyle = '#fff';
    ctx.font = 'bold 24px Arial';
    ctx.fillText('编号', 370, 450);
    ctx.fillText('姓名', 370, 500);
    ctx.fillText('日期', 370, 550);
    ctx.font = '24px Arial';
    const number = String(info.id + 10000).substr(1);
    ctx.fillText(`BOYU${number}`, 450, 450);
    ctx.fillText(info.name, 450, 500);
    ctx.fillText(moment().format('YYYY-MM-DD'), 450, 550);
  }
  const style = {
    position: 'absolute',
    left: '21%',
    top: '83%',
  };
  return <div style={{
    minHeight: '100vh',
    backgroundColor: '#EBF5FF',
  }}><Main
    token={token}
    template="https://staticcdn.boyuai.com/materials/2019/09/26/yHy7iRjm5w0XWZUZEICrh.jpg"
    width={750}
    height={1392}
    drawPortrait={drawPortrait}
    drawInfo={drawInfo}
  >
    <img src="https://staticcdn.boyuai.com/share/playground-save-hint.png" alt="长按保存证书" style={style} />
  </Main></div>;
});
