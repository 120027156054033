import React, { useRef, useEffect, useMemo } from 'react';
import WordCloud from 'wordcloud';

export default function TagCloud({ items, onFinish, color = '#2FB1F8', backgroundColor = '#F9FAFC', width = '550px', height = '760px' }) {
  const canvasRef = useRef(null);
  const list = useMemo(() => {
    if (!items || !items.length) {
      return [];
    }
    const allItems = items.slice(0, 60);
    for (let i = allItems.length; i < 60; i++) {
      const index = Math.floor(Math.random() * items.length);
      allItems.push(items[index]);
    }
    const result = allItems.map(item => {
      return [item, getNumberInNormalDistribution(24, 5) + 12];
    });
    return result;
  }, [items]);
  useEffect(() => {
    if (canvasRef.current && list.length) {
      canvasRef.current.addEventListener('wordcloudstop', () => {
        onFinish && onFinish(canvasRef.current);
      });
      WordCloud(canvasRef.current, {
        list,
        rotationSteps: 2,
        shape: 'square',
        shrinkToFit: true,
        color,
        fontWeight: 600,
        rotateRatio: 0.3,
        backgroundColor,
      })
    }
  }, [backgroundColor, color, list, onFinish]);
  return <canvas ref={canvasRef} width={width} height={height} />
}

function getNumberInNormalDistribution(mean, dev){
  return mean + (uniform2NormalDistribution() * dev);
}

function uniform2NormalDistribution(){
  let sum = 0.0;
  for (let i = 0; i < 12; i++) {
    sum = sum + Math.random();
  }
  return sum - 6.0;
}
