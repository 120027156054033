import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { autoWrapString, fillRoundRect } from '../../../utils';

// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// const monospaceFont = isMobile ? 'Monospace' : 'Consolas';
const monospaceFont = 'Consolas'; // 总结直接用这个

const handleText = (ctx, text, textYStep, textWidth) => {
    const displayTextList =
        text.length > 250
            ? autoWrapString(ctx, `${text.slice(0, 250)}...`, textWidth)
            : autoWrapString(ctx, text, textWidth);
    return [displayTextList, displayTextList.length * textYStep + 34];
};

const drawSummary = (ctx, height, resizeRatio, text) => {
    const codeX = (18 + 30 + 22) * resizeRatio;
    const codeY = (height + 12) * resizeRatio;
    const codeWidth = 274 * resizeRatio;
    const textYStep = 22 * resizeRatio;

    // 标题左侧的点
    ctx.beginPath(); //开始绘制
    ctx.arc(
        (18 + 30 + 4) * resizeRatio,
        (height + 12) * resizeRatio,
        4 * resizeRatio,
        0,
        2 * Math.PI
    ); //arc 的意思是“弧”
    ctx.fillStyle = '#2F318A'; //设置填充颜色
    ctx.fill(); //开始填充
    ctx.strokeStyle = '#2F318A';
    ctx.stroke();

    ctx.font = `normal ${Math.floor(16 * resizeRatio)}px ${monospaceFont}`;
    const [finalTextList, rectHeight] = handleText(
        ctx,
        text,
        22,
        274 * resizeRatio
    );
    // 绘制分析
    ctx.fillStyle = '#2F318A';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    finalTextList.forEach((line, i) => {
        let textWidth = ctx.measureText(line).width;
        while (textWidth > codeWidth) {
            textWidth = ctx.measureText(line).width;
            line = line.slice(0, line.length - 1);
        }
        ctx.fillText(`${line}`, codeX, codeY + i * textYStep);
    });
    return rectHeight;
};

export default function useSummaryBlock(width, resizeRatio, summary) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/10/29/yOnvbmyWXhzf8vDcx6vsS.png!png'
    );
    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && title && summary) {
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            // 计算高度
            // 白色块高度
            ctx.font = `bold ${Math.floor(
                16 * resizeRatio
            )}px ${monospaceFont}`;
            const [, textHeight] = handleText(
                ctx,
                summary,
                22,
                274 * resizeRatio
            );
            const blockHeight = textHeight + 16 + 16; // 16是底部留白 16 是标题下方空隙
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + 60;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            ctx.drawImage(
                title,
                18 * resizeRatio,
                currentHeight,
                127 * resizeRatio,
                60 * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += 42;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#E44B71');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 分析1
            currentHeight += 32;
            drawSummary(ctx, currentHeight, resizeRatio, summary);

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [resizeRatio, width, title, summary, image]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
