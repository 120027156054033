import { useState, useEffect, useCallback } from 'react';
import { remote } from '@boyuai/utils';

export default function useLearnLessonReportData(publicToken, lessonId) {
    const [reportData, setReportData] = useState(null);
    const generateReportData = useCallback(async () => {
        if (lessonId && publicToken) {
            const res = await remote.$get(
                `/learn-events/public/${publicToken}/lesson/${lessonId}`
            );
            if (res && res.reportData) {
                // 将时间放进去
                setReportData({ ...res.reportData, time: res.time });
            }
        }
    }, [lessonId, publicToken]);
    useEffect(() => {
        generateReportData();
    }, [generateReportData]);
    return reportData;
}
