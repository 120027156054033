import React, { useEffect, useRef, useState } from 'react';
import useReadImage from '../../../hooks/read-image';
import { fillRoundRect } from '../../../utils';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const monospaceFont = isMobile ? 'Monospace' : 'Consolas';

const drawTitle = (ctx, height, resizeRatio, title) => {
    // 标题左侧的点
    ctx.beginPath(); //开始绘制
    ctx.arc(
        (18 + 30 + 4) * resizeRatio,
        (height + 12) * resizeRatio,
        4 * resizeRatio,
        0,
        2 * Math.PI
    ); //arc 的意思是“弧”
    ctx.fillStyle = '#2F318A'; //设置填充颜色
    ctx.fill(); //开始填充
    ctx.strokeStyle = '#2F318A';
    ctx.stroke();
    // 标题
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#2F318A';
    ctx.font = `normal ${16 * resizeRatio}px Arial`;
    ctx.fillText(title, (18 + 53) * resizeRatio, (height + 12) * resizeRatio);
};

const handleCode = (code, codeYStep) => {
    const displayCodeList = code
        .split('\n')
        .filter((str) => !str.startsWith('# 【'))
        .filter((str) => !str.trim().startsWith('#'));
    const displayCodeListWithoutContinuousEmptyLines = [];
    let prevLineIsEmpty = true;
    for (const line of displayCodeList) {
        if (!line.trim()) {
            // 是空行
            if (!prevLineIsEmpty) {
                // 前一行不空
                prevLineIsEmpty = true;
            } else {
                // 前一行空
                continue;
            }
        } else {
            // 不是空行
            prevLineIsEmpty = false;
        }
        displayCodeListWithoutContinuousEmptyLines.push(line);
    }
    const finalCodes = displayCodeListWithoutContinuousEmptyLines.slice(0, 10);
    return [finalCodes, finalCodes.length * codeYStep + 34];
};

const drawCode = (ctx, height, resizeRatio, code) => {
    const rectX = (18 + 30) * resizeRatio;
    const rectY = height * resizeRatio;
    const codeX = (18 + 30 + 22) * resizeRatio;
    const codeY = (height + 17) * resizeRatio;
    const codeWidth = 274 * resizeRatio;
    const codeYStep = 18 * resizeRatio;

    const [finalCodes, rectHeight] = handleCode(code, 18);
    // 灰色框
    ctx.globalAlpha = 0.3;
    fillRoundRect(
        ctx,
        rectX,
        rectY,
        312 * resizeRatio,
        rectHeight * resizeRatio,
        10 * resizeRatio,
        '#2F318A'
    );
    ctx.globalAlpha = 1;
    // 绘制代码
    ctx.fillStyle = '#2F318A';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.font = `bold ${Math.floor(16 * resizeRatio)}px ${monospaceFont}`;
    finalCodes.forEach((line, i) => {
        let textWidth = ctx.measureText(line).width;
        while (textWidth > codeWidth) {
            textWidth = ctx.measureText(line).width;
            line = line.slice(0, line.length - 1);
        }
        ctx.fillText(`${line}`, codeX, codeY + i * codeYStep);
    });
    return rectHeight;
};

export default function useCodeBlock(width, resizeRatio, codeList) {
    const [image, setImage] = useState(null);
    // canvas
    const canvasRef = useRef(null);
    const context = useRef(null);
    const title = useReadImage(
        'https://staticcdn.boyuai.com/materials/2020/10/28/9TvAUoXN1AF4UNNfa8oK3.png!png'
    );
    useEffect(() => {
        if (image) {
            return;
        }
        if (canvasRef.current && title && codeList && codeList.length === 3) {
            // 计算高度
            // 白色块高度
            const blockHeight =
                codeList.reduce((prev, code) => {
                    const [, blockHeight] = handleCode(code, 18);
                    return prev + blockHeight + 29 + 32; // 29和32是标题上下的空隙
                }, 0) + 16; // 16是底部留白
            // canvas高度 = 白色块+标题
            const canvasHeight = blockHeight + 60;

            let currentHeight = 0;
            // 设置画布
            canvasRef.current.style.width = `${width}px`;
            canvasRef.current.style.height = `${canvasHeight * resizeRatio}px`;
            canvasRef.current.width = width;
            canvasRef.current.height = canvasHeight * resizeRatio;
            // 创建ctx
            context.current = canvasRef.current.getContext('2d');
            const ctx = context.current;
            ctx.drawImage(
                title,
                18 * resizeRatio,
                currentHeight,
                127 * resizeRatio,
                60 * resizeRatio
            );

            // 渐变圆角矩形（渐变边框）
            currentHeight += 42;
            const gradient = ctx.createLinearGradient(
                0,
                blockHeight * resizeRatio,
                width,
                0
            );
            gradient.addColorStop(0, '#E44B71');
            gradient.addColorStop(1, '#3A59C6');
            fillRoundRect(
                ctx,
                18 * resizeRatio,
                currentHeight * resizeRatio,
                376 * resizeRatio,
                blockHeight * resizeRatio,
                10 * resizeRatio,
                gradient
            );
            // 白色圆角矩形
            const strokeWidth = 2.5;
            fillRoundRect(
                ctx,
                (18 + strokeWidth) * resizeRatio,
                (currentHeight + strokeWidth) * resizeRatio,
                (376 - 2 * strokeWidth) * resizeRatio,
                (blockHeight - 2 * strokeWidth) * resizeRatio,
                10 * resizeRatio,
                '#fff'
            );

            // 代码1
            currentHeight += 32;
            drawTitle(ctx, currentHeight, resizeRatio, '数据爬取');
            currentHeight += 29;
            currentHeight += drawCode(
                ctx,
                currentHeight,
                resizeRatio,
                codeList[0]
            );

            // 代码2
            currentHeight += 24;
            drawTitle(ctx, currentHeight, resizeRatio, '数据处理');
            currentHeight += 29;
            currentHeight += drawCode(
                ctx,
                currentHeight,
                resizeRatio,
                codeList[1]
            );

            // 代码3
            currentHeight += 24;
            drawTitle(ctx, currentHeight, resizeRatio, '数据可视化');
            currentHeight += 29; // 标题下方的空间
            currentHeight += drawCode(
                ctx,
                currentHeight,
                resizeRatio,
                codeList[2]
            );

            // 生成图片
            setImage(canvasRef.current.toDataURL());
        }
    }, [resizeRatio, width, title, codeList, image]);

    return [
        useReadImage(image),
        <canvas ref={canvasRef} style={{ display: 'none' }} />,
    ];
}
