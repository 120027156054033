import { useState, useEffect } from 'react';
import { remote } from '@boyuai/utils';

const DEFAULT_PORTRAIT =
    'https://staticcdn.boyuai.com/materials/2019/11/30/wiOKZfvqnfL_aHV9NZ0RJ.jpg';

export default function useUserInfo(
    publicToken,
    defaultPortrait = DEFAULT_PORTRAIT
) {
    const [info, setInfo] = useState(null);
    useEffect(() => {
        if (!publicToken) {
            return;
        }
        remote.$get(`/user/public/${publicToken}`).then((data) => {
            if (!data) {
                return data;
            }
            setInfo({
                ...data,
                portrait: data.portrait || defaultPortrait,
            });
        });
    }, [publicToken, defaultPortrait]);
    return info;
}
